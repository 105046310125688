import React from "react";
import { motion } from "framer-motion";
import styles from "../../access.module.css";
import { useTranslation } from "react-i18next";

const IuapAcM = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/06-acesspoint/wifi-ip-com/details/iuapAc/models/01.png`}
          alt={t("iuapAcM.productImageAlt")}
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          style={{ color: "red" }}
        >
          {t("iuapAcM.title")}
          <p style={{ fontSize: "20px", color: "#000" }}>
            {t("iuapAcM.subtitle")}
          </p>
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          {["feature1", "feature2", "feature3", "feature4", "feature5","feature6"].map(
            (feature, index) => (
              <motion.li
                key={feature}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 + index * 0.2, duration: 0.6 }}
              >
                {t(`iuapAcM.${feature}`)}
              </motion.li>
            )
          )}
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default IuapAcM;
