import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function TerreSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series`;
  const { t } = useTranslation();
  // const isArabic = i18n.language === "ar";
  const modelsData = [
    {
      id: 1,
      name: "TER-4G",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "TER-4G-A",
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: "TER-ACT",
      image: `${modelBasePath}/models/03.png`,
    },
    {
      id: 4,
      name: "TER-ACT-A",
      image: `${modelBasePath}/models/04.png`,
    },
    {
      id: 5,
      name: "TER-AUD",
      image: `${modelBasePath}/models/05.png`,
    },
    {
      id: 6,
      name: "TER-AUD-A",
      image: `${modelBasePath}/models/06.png`,
    },
  ];

  return (
    <div className={styles.container}>
      {/* --------01-------*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" ,display: "block"}}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/01.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/01.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "20%" : "20%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "90%" : "90%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title1"),
              subtitle: t("terre.subtitle1"),
            }}
            textAlign="center"
            margin="20px 0"
            padding="10px"
            direction="up"
            zoom={true}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            Product Models
          </h1>
          <div>
            <ModelSlider models={modelsData} />
          </div>
        </div>
      </div>

      {/* 02*/}
      <div
        className={styles.videoContainer}
        style={{ position: "relative", width: "100vw" }}
      >
        <VideoAsGif
          className={styles.videoContainer}
          style={{ width: "100vw" }}
          videoSrc={`${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/02.mp4`
          }
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "50%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "70%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "left",
            width: isSmallScreen ? "100%" : "100%",
          }}
        >
          <div
            style={{
              width: isSmallScreen ? "80%" : "50%",
              margin: isSmallScreen ? "0 auto" : "auto",
            }}
          ></div>
        </div>
      </div>

      {/* 04*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/03.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/03.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "70%" : "60%", // تعديل top
            left: isSmallScreen ? "83%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: isSmallScreen ? "center" : "left",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title2"),
              subtitle: t("terre.subtitle2"),
            }}
            color={isSmallScreen ? "#000" : "#fff"}
            textAlign={isSmallScreen ? "left" : "center"}
            margin="20px 0"
            padding="10px"
            direction="right"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 05  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/04.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/04.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "20%" : "20%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "left",
            width: isSmallScreen ? "80%" : "35%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title3"),
              subtitle: t("terre.subtitle3"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
            // backgroundColor={ isSmallScreen ? "" : "#b08968" }
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/05.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/05.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "30%" : "30%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "60%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title4"),
              subtitle: t("terre.subtitle4"),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/06.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/06.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "30%" : "45%", // تعديل top
            left: isSmallScreen ? "50%" : "105%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "90%" : "90%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title5"),
              subtitle: t("terre.subtitle5"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "left" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 07  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/07.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/07.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "85%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "45%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title6"),
              subtitle: t("terre.subtitle6"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "center" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 07  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/08.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/08.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "30%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "57%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title7"),
              subtitle: t("terre.subtitle7"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "center" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 07  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/09.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/09.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "30%" : "25%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title8"),
              subtitle: t("terre.subtitle8"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 07  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/10.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/10.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "50%" : "50%", // تعديل top
            left: isSmallScreen ? "57%" : "57%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "90%" : "90%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title9"),
              subtitle: t("terre.subtitle9"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "left" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 07  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/smallScreens/11.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/terre-series/11.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "25%" : "25%", // تعديل top
            left: isSmallScreen ? "50%" : "55%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("terre.title10"),
              subtitle: t("terre.subtitle10"),
            }}
            color="#000"
            textAlign={isSmallScreen ? "center" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>
    </div>
  );
}

export default TerreSeries;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
