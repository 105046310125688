import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function EuropeSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  //   const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/europe-series`;
  const { t } = useTranslation();
  //   const isArabic = i18n.language === "ar";
  const modelsData = [
    {
      id: 1,
      name: "TIS-ERO-4G",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "TIS-ERO-6G",
      image: `${modelBasePath}/models/02.png`,
    },
  ];

  return (
    <div className={styles.container}>
      {/* --------01-------*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%", display: "block" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/europe-series/smallScreens/01.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/europe-series/01.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "20%" : "20%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "90%" : "90%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: t("europe.subtitle1"),
              subtitle: ``,
            }}
            textAlign="center"
            margin="20px 0"
            padding="10px"
            direction="up"
            zoom={true}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            Product Models
          </h1>
          <div>
            <ModelSlider models={modelsData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EuropeSeries;
