import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";
import GangSwitch from "./GangSwitch";
import MarbleWood from "./MarbleWood";

function VenBus() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBasePath = isSmallScreen
    ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/smallScreens`
    : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus`;

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus`;

  const videoBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus`; // Assuming video path

  const { t } = useTranslation();
  const isArabic = i18n.language === "ar";
  
  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: ``,
      subtitle: ``,
      top: isSmallScreen ? "50%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "02.jpg" : "02.jpg",
      title: "",
      subtitle: t('venera.subtitle1'), // تطبيق الترجمة على subtitle
      top: isSmallScreen ? "88%" : "48%",
      left: isSmallScreen ? "55%" : "82%",

      textAlign: isSmallScreen
        ? isArabic
          ? "right"
          : "left"
        : isArabic
        ? "right"
        : "left",

      width: isSmallScreen ? "45%" : "30%",
      direction: "down",
      zoom: true,
      color: "#000",
    },
    // {
    //   type: "image",
    //   src: isSmallScreen ? "03.jpg" : "03.jpg",
    //   title: "",
    //   subtitle: t('venera.subtitle2'), // تطبيق الترجمة على subtitle
    //   top: isSmallScreen ? "85%" : "40%",
    //   left: isSmallScreen ? "62%" : "33%",
    //   textAlign: "left",
    //   width: isSmallScreen ? "80%" : "30%",
    //   direction: "right",
    //   color: "#000",
    // },
    {
      type: "image",
      src: isSmallScreen ? "04.jpg" : "04.jpg",
      title: "",
      subtitle: t('venera.subtitle3'), // تطبيق الترجمة على subtitle
      top: isSmallScreen ? "80%" : "48%",
      left: isSmallScreen ? "50%" : "78%",
      textAlign: isSmallScreen ? "center" : "left",
      width: "30%",
      direction: "right",
      zoom: true,
      color: "#fff",
    },
    {
      type: "image",
      src: "05.jpg",
      title: "",
      subtitle: t('venera.subtitle4'), // تطبيق الترجمة على subtitle
      top: isSmallScreen ? "80%" : "48%",
      left: isSmallScreen ? "50%" : "30%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "60%" : "25%",
      direction: "down",
      zoom: true,
      color: "#fff",
    },
    // {
    //   type: "image",
    //   src: "06.jpg",
    //   title: "",
    //   subtitle: t('venera.subtitle5'), // تطبيق الترجمة على subtitle
    //   top: isSmallScreen ? "82%" : "50%",
    //   left: isSmallScreen ? "65%" : "85%",
    //   textAlign: isSmallScreen ? "center" : "left",
    //   width: isSmallScreen ? "80%" : "30%",
    //   direction: "left",
    //   zoom: true,
    //   color: "#fff",
    // },
  ];

  const modelsData = [
    {
      id: 1,
      name: "VEN-2S-BUS",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "VEN-3S-BUS",
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: "VEN-4S-BUS",
      image: `${modelBasePath}/models/03.png`,
    },
    {
      id: 4,
      name: "VEN-6S-BUS",
      image: `${modelBasePath}/models/04.png`,
    },
    {
      id: 5,
      name: "VEN-AC-3R-BUS",
      image: `${modelBasePath}/models/05.png`,
    },
  ];
  return (
    <div className={styles.container}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      )) }
      <GangSwitch />
      <MarbleWood/>
    </div>
  );
}

export default VenBus;
