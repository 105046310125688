import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';
import './i18n';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <App />
    </>
  </React.StrictMode>
);
