import React, { Suspense, lazy } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./00-Main/footer/Footer";
import ScrollToTop from "./00-Main/ScrollToTop/ScrollToTop";
import Scroller from "./00-Main/Scroller/Scroller";
import WhatsappPopup from "./00-Main/WhatsappPopup/WhatsappPopup";
import Spinner from "./00-Main/spinner/Spinner";

import LunaSeries from "./products/BusProducts/BusDetails/panel/LunaSeries";

// Bus Products
// Panels;

import Titan from "./products/BusProducts/BusDetails/panel/Titan";

import VenBus from "./products/BusProducts/BusDetails/panel/VenBus";

import MercurySeries from "./products/BusProducts/BusDetails/panel/MercurySeries";

import SolSeries from "./products/BusProducts/BusDetails/panel/SolSeries";

import TerreSeries from "./products/BusProducts/BusDetails/panel/TerreSeries";

import EuropeSeries from "./products/BusProducts/BusDetails/panel/EuropeSeries";

import SiriusSeries from "./products/BusProducts/BusDetails/panel/SiriusSeries";

import MarsSeries from "./products/BusProducts/BusDetails/panel/MarsSeries";

// Hotel-interface

import MercuryHotel from "./products/BusProducts/BusDetails/HotelInterface/MercuryHotel";

import ClickSeries from "./products/BusProducts/BusDetails/HotelInterface/ClickSeries";

import IoHotel from "./products/BusProducts/BusDetails/HotelInterface/IoHotel";

import LunaHotel from "./products/BusProducts/BusDetails/HotelInterface/LunaHotel";

import TerraHotel from "./products/BusProducts/BusDetails/HotelInterface/TerraHotel";

// Screens

import SatOne from "./products/BusProducts/BusDetails/Screens/SatOne";

import SatTwo from "./products/BusProducts/BusDetails/Screens/SatTwo";

import SatThree from "./products/BusProducts/BusDetails/Screens/SatThree";

import SaturnRemote from "./products/BusProducts/BusDetails/Screens/SaturnRemote";

import SaturnPanel from "./products/BusProducts/BusDetails/Screens/SaturnPanel";

// Dimmer

import LeadingEdge from "./products/BusProducts/BusDetails/Dimmers/LeadingEdge";

import TrailingEdge from "./products/BusProducts/BusDetails/Dimmers/TrailingEdge";

import TenVDimmer from "./products/BusProducts/BusDetails/Dimmers/TenVDimmer";

import Dali from "./products/BusProducts/BusDetails/Dimmers/Dali";

import Dmx from "./products/BusProducts/BusDetails/Dimmers/Dmx";

import IndustrialDimmers from "./products/BusProducts/BusDetails/Dimmers/IndustrialDimmers";

// Relays

import Relays from "./products/BusProducts/BusDetails/Relays/Relays";

import Hvac from "./products/BusProducts/BusDetails/Relays/Hvac";

import MiniRelay from "./products/BusProducts/BusDetails/Relays/MiniRelay";

import RcuController from "./products/BusProducts/BusDetails/Relays/RcuController";

// Sensors
import OccupancySensor from "./products/BusProducts/BusDetails/Sensors/OccupancySensor";
import HealthSensor from "./products/BusProducts/BusDetails/Sensors/HealthSensor";
import TenFunctionSensor from "./products/BusProducts/BusDetails/Sensors/TenFunctionSensor";
import WaterLeakSensor from "./products/BusProducts/BusDetails/Sensors/WaterLeakSensor";
import MotionsSensors from "./products/BusProducts/BusDetails/Sensors/MotionsSensors";

// BMS/PMS
import Dl from "./products/BusProducts/BusDetails/Bms/Dl";
import VInput from "./products/BusProducts/BusDetails/Bms/VInput";
import MaModule from "./products/BusProducts/BusDetails/Bms/MaModule";
import ZoneInput from "./products/BusProducts/BusDetails/Bms/ZoneInput";
import TemperatureSensorsTimer from "./products/BusProducts/BusDetails/Bms/TemperatureSensorsTimer";
import AutTimer from "./products/BusProducts/BusDetails/Bms/AutTimer";
import SecurityModule from "./products/BusProducts/BusDetails/Bms/SecurityModule";

// Motors
import CurtainMotor from "./products/BusProducts/BusDetails/Motors/CurtainMotor";
import RollerMotor from "./products/BusProducts/BusDetails/Motors/RollerMotor";
import ProjectorLift from "./products/BusProducts/BusDetails/Motors/ProjectorLift";

// Audio/Video
import ZoneAmplifier from "./products/BusProducts/BusDetails/AudioVideo/ZoneAmplifier";
import AudioPlayer from "./products/BusProducts/BusDetails/AudioVideo/AudioPlayer";
import AvIpMatrix from "./products/BusProducts/BusDetails/AudioVideo/AvIpMatrix";
import AudioMatrix from "./products/BusProducts/BusDetails/AudioVideo/AudioMatrix";
import IrEmitter from "./products/BusProducts/BusDetails/AudioVideo/IrEmitter";
import Speakers from "./products/BusProducts/BusDetails/AudioVideo/Speakers";

// Meters
import WaterMeter from "./products/BusProducts/BusDetails/Meters/WaterMeter";
import PhaseEnergyMeter from "./products/BusProducts/BusDetails/Meters/PhaseEnergyMeter";
import EnergyMeter from "./products/BusProducts/BusDetails/Meters/EnergyMeter";
import WeatherStation from "./products/BusProducts/BusDetails/Meters/WeatherStation";

// Gateways
import Two from "./products/BusProducts/BusDetails/Gateways/Two";
import AcGty from "./products/BusProducts/BusDetails/Gateways/AcGty";
import VrfAcGatway from "./products/BusProducts/BusDetails/Gateways/VrfAcGatway";
import ModbusVrfGateways from "./products/BusProducts/BusDetails/Gateways/ModbusVrfGateways";
import GatewayBusConverter from "./products/BusProducts/BusDetails/Gateways/GatewayBusConverter";
import KnxGateway from "./products/BusProducts/BusDetails/Gateways/KnxGateway";

// Other
import RemoteControl from "./products/BusProducts/BusDetails/Other/RemoteControl";
import PowerSupply from "./products/BusProducts/BusDetails/Other/PowerSupply";
import CableManager from "./products/BusProducts/BusDetails/Other/CableManager";
import IrLearner from "./products/BusProducts/BusDetails/Other/IrLearner";
import Accessories from "./products/BusProducts/BusDetails/Other/Accessories";
import DistributorBox from "./products/BusProducts/BusDetails/Other/DistributorBox";
import PanelAddition from "./products/BusProducts/BusDetails/Other/PanelAddition";

// Air

// Panels
import VeneraSwitch from "./products/AirProducts/AirDetails/Panels/VeneraSwitch";
import VeneraDimmer from "./products/AirProducts/AirDetails/Panels/VeneraDimmer";
import VeneraThermostat from "./products/AirProducts/AirDetails/Panels/VeneraThermostat";

// Controllers
import AdsController from "./products/AirProducts/AirDetails/Controllers/AdsController";
import AcmSwitch from "./products/AirProducts/AirDetails/Controllers/AcmSwitch";
import AirSocket from "./products/AirProducts/AirDetails/Controllers/AirSocket";
import AirIrEmitter from "./products/AirProducts/AirDetails/Controllers/AirIrEmitter";
import TrvController from "./products/AirProducts/AirDetails/Controllers/TrvController";

// Sensors
// import MotionSensor from "./products/AirProducts/AirDetails/Sensors/AirMotionSensor";
import AirFunctionSensors from "./products/AirProducts/AirDetails/Sensors/AirFunctionSensors";
import DigitalInputs from "./products/AirProducts/AirDetails/Sensors/DigitalInputs";

// Intercom
import TouchScreen from "./products/AirProducts/AirDetails/Intercom/TouchScreen";
import OutdoorTower from "./products/AirProducts/AirDetails/Intercom/OutdoorTower";
import OutdoorBuilding from "./products/AirProducts/AirDetails/Intercom/OutdoorBuilding";
import OutdoorUnit from "./products/AirProducts/AirDetails/Intercom/OutdoorUnit";

// Gateways
import AirBusConverter from "./products/AirProducts/AirDetails/Gateways/AirBusConverter";
import ModbusVrf from "./products/AirProducts/AirDetails/Gateways/ModbusVrf";

// Bee

// Wall Switch
import DionCurtainSwitch from "./products/BeeProducts/BeeDetails/WallSwitch/DionCurtainSwitch";
import VeneraZigbeeSwitch from "./products/BeeProducts/BeeDetails/WallSwitch/VeneraZigbeeSwitch";
import DionSwitch from "./products/BeeProducts/BeeDetails/WallSwitch/DionSwitch";
import DionWallRemote from "./products/BeeProducts/BeeDetails/WallSwitch/DionWallRemote";

// Controller
import ZigbeeMotor from "./products/BeeProducts/BeeDetails/Controller/ZigbeeMotor";
import PlugUkEu from "./products/BeeProducts/BeeDetails/Controller/PlugUkEu";
import ValveGasWater from "./products/BeeProducts/BeeDetails/Controller/ValveGasWater";
import SwitchModule from "./products/BeeProducts/BeeDetails/Controller/SwitchModule";
import DimmerModule from "./products/BeeProducts/BeeDetails/Controller/DimmerModule";
import ShutterModule from "./products/BeeProducts/BeeDetails/Controller/ShutterModule";
import RgbwDriver from "./products/BeeProducts/BeeDetails/Controller/RgbwDriver";

// Security/Sensors
import DoorContact from "./products/BeeProducts/BeeDetails/SecuritySensors/DoorContact";
import PirSensor from "./products/BeeProducts/BeeDetails/SecuritySensors/PirSensor";
import SmokeDetector from "./products/BeeProducts/BeeDetails/SecuritySensors/SmokeDetector";
import GasSensor from "./products/BeeProducts/BeeDetails/SecuritySensors/GasSensor";
import WaterLeak from "./products/BeeProducts/BeeDetails/SecuritySensors/WaterLeak";
import CoSensor from "./products/BeeProducts/BeeDetails/SecuritySensors/CoSensor";
import KeyFob from "./products/BeeProducts/BeeDetails/SecuritySensors/KeyFob";
import Siren from "./products/BeeProducts/BeeDetails/SecuritySensors/Siren";

// Lights
import RgbwBulb from "./products/BeeProducts/BeeDetails/Lights/RgbwBulb";

// Gateway
import ZigbeeBusHub from "./products/BeeProducts/BeeDetails/Gateway/ZigbeeBusHub";

// Tis Lock

// Hotel Lock
import LockOne from "./products/LocksProducts/LockDetails/HotelLock/LockOne";
import LockTwo from "./products/LocksProducts/LockDetails/HotelLock/LockTwo";
import LockThree from "./products/LocksProducts/LockDetails/HotelLock/LockThree";
import LockFour from "./products/LocksProducts/LockDetails/HotelLock/LockFour";
import LockFive from "./products/LocksProducts/LockDetails/HotelLock/LockFive";

// Smart Lock
import SmartOne from "./products/LocksProducts/LockDetails/SmartLock/SmartOne";
import SmartTwo from "./products/LocksProducts/LockDetails/SmartLock/SmartTwo";
import SmartThree from "./products/LocksProducts/LockDetails/SmartLock/SmartThree";
import SmartFour from "./products/LocksProducts/LockDetails/SmartLock/SmartFour";
import SmartFive from "./products/LocksProducts/LockDetails/SmartLock/SmartFive";
import AirMotionSensor from "./products/AirProducts/AirDetails/Sensors/AirMotionSensor";

// Hikvision
// interCom
import PoeVilla from "./products/HikProducts/HikDetails/InterCom/PoeVilla";
import KhIpBased from "./products/HikProducts/HikDetails/InterCom/KhIpBased";
import KhEight from "./products/HikProducts/HikDetails/InterCom/KhEight";
import KdProModular from "./products/HikProducts/HikDetails/InterCom/KdProModular";

//Out
import EightMSmart from "./products/HikProducts/HikDetails/Out/EightMSmart";
import FourKWdr from "./products/HikProducts/HikDetails/Out/FourKWdr";
import FourMColorVu from "./products/HikProducts/HikDetails/Out/FourMColorVu";
import FourMWdr from "./products/HikProducts/HikDetails/Out/FourMWdr";

// Acess

// Wifi ip com
import GTwo from "./products/AcessProducts/AcessDetails/WifiIpCom/GTwo";
import IuapAcM from "./products/AcessProducts/AcessDetails/WifiIpCom/IuapAcM";
import ProSexIw from "./products/AcessProducts/AcessDetails/WifiIpCom/ProSexIw";
import ProSexLite from "./products/AcessProducts/AcessDetails/WifiIpCom/ProSexLite";

// Unifi
import USevenOutDoor from "./products/AcessProducts/AcessDetails/Unifi/USevenOutDoor";
import USevenProMax from "./products/AcessProducts/AcessDetails/Unifi/USevenProMax";
import USevenProWall from "./products/AcessProducts/AcessDetails/Unifi/USevenProWall";

// Tp link
import EapSeven from "./products/AcessProducts/AcessDetails/TpLink/EapSeven";
import EapSexOutdoor from "./products/AcessProducts/AcessDetails/TpLink/EapSexOutdoor";
import EapSexWall from "./products/AcessProducts/AcessDetails/TpLink/EapSexWall";
import SgThreeM from "./products/AcessProducts/AcessDetails/TpLink/SgThreeM";

// D link
import EightX from "./products/AcessProducts/AcessDetails/DLink/EightX";
import FoutryEightX from "./products/AcessProducts/AcessDetails/DLink/FoutryEightX";
import SixteenX from "./products/AcessProducts/AcessDetails/DLink/SixteenX";
import TwentyFourX from "./products/AcessProducts/AcessDetails/DLink/TwentyFourX";
import TwentyFourXT from "./products/AcessProducts/AcessDetails/DLink/TwentyFourXT";

// Sound

// Bosch
import CeilingBosch from "./products/SoundProducts/SoundDetails/Bosch/CeilingBosch";
import Cabinet from "./products/SoundProducts/SoundDetails/Bosch/Cabinet";

// Bose
import CeilingBose from "./products/SoundProducts/SoundDetails/Bose/CeilingBose";
import SurfaceMount from "./products/SoundProducts/SoundDetails/Bose/SurfaceMount";

// project section
import ProjectOne from "./home/ProjectsSection/Details/ProjectOne";
import ProjectTwo from "./home/ProjectsSection/Details/ProjectTwo";
import ProjectThree from "./home/ProjectsSection/Details/ProjectThree";
import ProjectFour from "./home/ProjectsSection/Details/ProjectFour";

// استخدم React.lazy لتحميل المكونات عند الحاجة
const Home = lazy(() => import("./home/Home"));
const Products = lazy(() => import("./products/Products"));
const About = lazy(() => import("./about/About"));
const Contact = lazy(() => import("./contact/Contact"));

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/about" element={<About />} />

            {/* Bus */}
            {/* 1-panels */}
            <Route path="/products/luna-series" element={<LunaSeries />} />
            <Route path="/products/titan" element={<Titan />} />
            <Route path="/products/ven-bus" element={<VenBus />} />
            <Route
              path="/products/mercury-series"
              element={<MercurySeries />}
            />
            <Route path="/products/sol-series" element={<SolSeries />} />
            <Route path="/products/terre-series" element={<TerreSeries />} />
            <Route path="/products/europe-series" element={<EuropeSeries />} />
            <Route path="/products/sirius-series" element={<SiriusSeries />} />
            <Route path="/products/mars-series" element={<MarsSeries />} />

            {/* 2-hotel-interface */}
            <Route
              path="/products/mercury-hotel-series"
              element={<MercuryHotel />}
            />
            <Route path="/products/click-series" element={<ClickSeries />} />
            <Route path="/products/io-hotel-series" element={<IoHotel />} />
            <Route path="/products/luna-hotel-series" element={<LunaHotel />} />
            <Route
              path="/products/terre-hotel-series"
              element={<TerraHotel />}
            />
            {/* 3-Screens */}
            <Route path="/products/sat133" element={<SatOne />} />
            <Route path="/products/sat101" element={<SatTwo />} />
            <Route path="/products/sat40" element={<SatThree />} />
            <Route path="/products/saturn-remote" element={<SaturnRemote />} />
            <Route path="/products/saturn-panel" element={<SaturnPanel />} />
            {/* Dimmer */}
            <Route
              path="/products/leading-edge-dimmer"
              element={<LeadingEdge />}
            />
            <Route
              path="/products/trailing-edge-dimmer"
              element={<TrailingEdge />}
            />
            <Route path="/products/0-10v-dimmer" element={<TenVDimmer />} />
            <Route path="/products/dail" element={<Dali />} />
            <Route path="/products/dmx" element={<Dmx />} />
            <Route
              path="/products/industrial-dimmers"
              element={<IndustrialDimmers />}
            />
            {/* Relays */}
            <Route path="/products/relays" element={<Relays />} />
            <Route path="/products/hvac" element={<Hvac />} />
            <Route path="/products/mini-relay" element={<MiniRelay />} />
            <Route
              path="/products/rcu-controller"
              element={<RcuController />}
            />
            {/* sensors */}
            <Route
              path="/products/occupancy-sensor"
              element={<OccupancySensor />}
            />
            <Route path="/products/health-sensor" element={<HealthSensor />} />
            <Route
              path="/products/10-function-sensor"
              element={<TenFunctionSensor />}
            />
            <Route
              path="/products/motion-sensor"
              element={<MotionsSensors />}
            />
            <Route
              path="/products/water-leak-sensor"
              element={<WaterLeakSensor />}
            />

            {/* BMS */}
            <Route path="/products/22dl" element={<Dl />} />
            <Route path="/products/tenv-input" element={<VInput />} />
            <Route path="/products/ma-module" element={<MaModule />} />
            <Route path="/products/zone-input" element={<ZoneInput />} />
            <Route
              path="/products/temp-sensor"
              element={<TemperatureSensorsTimer />}
            />
            <Route path="/products/aut-timer" element={<AutTimer />} />
            <Route
              path="/products/security-module"
              element={<SecurityModule />}
            />

            {/* Motors */}
            <Route path="/products/curtain-motor" element={<CurtainMotor />} />
            <Route path="/products/roller-motor" element={<RollerMotor />} />
            <Route
              path="/products/projector-lift"
              element={<ProjectorLift />}
            />

            {/* Audio/Video */}
            <Route
              path="/products/zone-zmplifier"
              element={<ZoneAmplifier />}
            />
            <Route path="/products/audio-player" element={<AudioPlayer />} />
            <Route path="/products/av-ip-matrix" element={<AvIpMatrix />} />
            <Route path="/products/audio-matrix" element={<AudioMatrix />} />
            <Route path="/products/ir-emitter" element={<IrEmitter />} />
            <Route path="/products/speakers" element={<Speakers />} />

            {/* Meters */}
            <Route path="/products/water-meter" element={<WaterMeter />} />
            <Route
              path="/products/phase-energy-meter"
              element={<PhaseEnergyMeter />}
            />
            <Route path="/products/energy-meter" element={<EnergyMeter />} />
            <Route
              path="/products/weather-station"
              element={<WeatherStation />}
            />

            {/* Gateways */}
            <Route path="/products/gateway-one" element={<Two />} />
            <Route path="/products/ac-gty" element={<AcGty />} />
            <Route path="/products/vrf-ac-gateway" element={<VrfAcGatway />} />
            <Route
              path="/products/mod-bus-vrf"
              element={<ModbusVrfGateways />}
            />
            <Route
              path="/products/gateway-bus-converter"
              element={<GatewayBusConverter />}
            />
            <Route path="/products/knx-gateway" element={<KnxGateway />} />

            {/* Other */}
            <Route
              path="/products/remote-control"
              element={<RemoteControl />}
            />
            <Route path="/products/power-supply" element={<PowerSupply />} />
            <Route path="/products/cable-manager" element={<CableManager />} />
            <Route path="/products/ir-learner" element={<IrLearner />} />
            <Route path="/products/accessories" element={<Accessories />} />
            <Route
              path="/products/aistributor-box"
              element={<DistributorBox />}
            />
            <Route
              path="/products/panel-addition"
              element={<PanelAddition />}
            />

            {/* Air */}

            {/* panel */}
            <Route path="/products/venera-switch" element={<VeneraSwitch />} />
            <Route path="/products/venera-dimmer" element={<VeneraDimmer />} />
            <Route
              path="/products/venera-thermostat"
              element={<VeneraThermostat />}
            />

            {/* controller */}
            <Route
              path="/products/ads-controller"
              element={<AdsController />}
            />
            <Route path="/products/acm-switch" element={<AcmSwitch />} />
            <Route path="/products/air-socket" element={<AirSocket />} />
            <Route path="/products/air-ir-emitter" element={<AirIrEmitter />} />
            <Route
              path="/products/trv-controller"
              element={<TrvController />}
            />

            {/* Sensors */}
            <Route
              path="/products/air-motion-sensor"
              element={<AirMotionSensor />}
            />
            <Route
              path="/products/air-10-function-sensor"
              element={<AirFunctionSensors />}
            />
            <Route
              path="/products/3-digital-inputs"
              element={<DigitalInputs />}
            />

            {/* Intercom */}
            <Route path="/products/7-touch-screen" element={<TouchScreen />} />
            <Route path="/products/outdoor-tower" element={<OutdoorTower />} />
            <Route
              path="/products/outdoor-building"
              element={<OutdoorBuilding />}
            />
            <Route path="/products/outdoor-unit" element={<OutdoorUnit />} />

            {/* Gateways */}
            <Route
              path="/products/air-bus-converter"
              element={<AirBusConverter />}
            />
            <Route
              path="/products/modbus-vrf-upb-gateway"
              element={<ModbusVrf />}
            />

            {/* Bee */}

            {/* Wall Switch */}
            <Route
              path="/products/dion-curtain-switch"
              element={<DionCurtainSwitch />}
            />
            <Route
              path="/products/venera-zigbee-switch"
              element={<VeneraZigbeeSwitch />}
            />
            <Route path="/products/dion-switch" element={<DionSwitch />} />
            <Route
              path="/products/dion-wall-remote"
              element={<DionWallRemote />}
            />

            {/* Controller */}
            <Route path="/products/zigbee-motor" element={<ZigbeeMotor />} />
            <Route path="/products/plug-uk/eu" element={<PlugUkEu />} />
            <Route
              path="/products/valve-gas-water"
              element={<ValveGasWater />}
            />
            <Route path="/products/switch-module" element={<SwitchModule />} />
            <Route path="/products/dimmer-module" element={<DimmerModule />} />
            <Route
              path="/products/shutter-module"
              element={<ShutterModule />}
            />
            <Route path="/products/rgbw-driver" element={<RgbwDriver />} />

            {/* Security/Sensor*/}
            <Route path="/products/door-contact" element={<DoorContact />} />
            <Route path="/products/pir-sensor" element={<PirSensor />} />
            <Route
              path="/products/smoke-detector"
              element={<SmokeDetector />}
            />
            <Route path="/products/gas-sensor" element={<GasSensor />} />
            <Route path="/products/water-leak" element={<WaterLeak />} />
            <Route path="/products/co-sensor" element={<CoSensor />} />
            <Route path="/products/key-fob" element={<KeyFob />} />
            <Route path="/products/siren" element={<Siren />} />

            {/* Lights */}
            <Route path="/products/rgbw-bulb" element={<RgbwBulb />} />

            {/* Gateway */}
            <Route path="/products/zigbee-bus-hub" element={<ZigbeeBusHub />} />

            {/* Tis Lock */}

            {/* Hotel Lock */}
            <Route path="/products/tis-e908" element={<LockOne />} />
            <Route path="/products/tis-s181" element={<LockTwo />} />
            <Route path="/products/tis-e901" element={<LockThree />} />
            <Route path="/products/tis-e300" element={<LockFour />} />
            <Route path="/products/tis-e200" element={<LockFive />} />

            {/* Smart Lock */}
            <Route path="/products/tis-k710" element={<SmartOne />} />
            <Route path="/products/tis-h168" element={<SmartTwo />} />
            <Route path="/products/tis-a270" element={<SmartThree />} />
            <Route path="/products/tis-a233" element={<SmartFour />} />
            <Route path="/products/tis-a210" element={<SmartFive />} />

            {/* Hik */}

            {/* interCom */}
            <Route
              path="/products/kd8-series-pro-modular"
              element={<KdProModular />}
            />
            <Route path="/products/poe-villa" element={<PoeVilla />} />
            <Route
              path="/products/kh6-series-ip-based"
              element={<KhIpBased />}
            />
            <Route path="/products/kh8-series" element={<KhEight />} />

            {/* Out */}
            <Route
              path="/products/4mp-wdr-fixed-Bullet"
              element={<FourMWdr />}
            />
            <Route path="/products/4mp-colorVu" element={<FourMColorVu />} />
            <Route
              path="/products/4k-wdr-fixed-Bullet"
              element={<FourKWdr />}
            />
            <Route
              path="/products/8mp-smart-hybrid-light"
              element={<EightMSmart />}
            />

            {/* Acess */}

            {/* Wifi ip com */}
            <Route path="/products/pro-6-lite" element={<ProSexLite />} />
            <Route path="/products/pro-6-iw" element={<ProSexIw />} />
            <Route path="/products/iuap-ac-m" element={<IuapAcM />} />
            <Route path="/products/g2216d" element={<GTwo />} />

            {/* unifi */}
            <Route path="/products/u7-pro-max" element={<USevenProMax />} />
            <Route path="/products/u7-pro-wall" element={<USevenProWall />} />
            <Route path="/products/u7-outdoor" element={<USevenOutDoor />} />

            {/* tpLink */}
            <Route path="/products/eap655-wall" element={<EapSexWall />} />
            <Route path="/products/eap783" element={<EapSeven />} />
            <Route
              path="/products/eap650-outdoor"
              element={<EapSexOutdoor />}
            />
            <Route path="/products/sg3218xp-m2" element={<SgThreeM />} />

            {/* Dlink */}
            <Route path="/products/8-x-1000base" element={<EightX />} />
            <Route path="/products/16-x-1000base-t" element={<SixteenX />} />
            <Route
              path="/products/24-x-1000base-t"
              element={<TwentyFourXT />}
            />
            <Route path="/products/24-x-1000base" element={<TwentyFourX />} />
            <Route
              path="/products/48-x-1000base-t"
              element={<FoutryEightX />}
            />

            {/* SoundProducts */}

            {/* Bosch */}
            <Route
              path="/products/ceiling-loudspeaker-6w"
              element={<CeilingBosch />}
            />
            <Route path="/products/cabinet-loudspeaker" element={<Cabinet />} />

            {/* Bose */}
            <Route
              path="/products/ceiling-loud-speakers"
              element={<CeilingBose />}
            />
            <Route
              path="/products/surface-mount-loudspeakers"
              element={<SurfaceMount />}
            />

            {/* project Section */}
            <Route path="/project/1" element={<ProjectOne />} />
            <Route path="/project/2" element={<ProjectTwo />} />
            <Route path="/project/3" element={<ProjectThree />} />
            <Route path="/project/4" element={<ProjectFour />} />
          </Routes>
        </Suspense>
        <Scroller />
        <div style={{ zIndex: 10000 }}>
          <ScrollToTop />
        </div>
        <WhatsappPopup />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
