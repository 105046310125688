import React, { useEffect, useRef } from "react";
import styles from './Scroller.module.css'; // استيراد CSS Modules

const Scroller = () => {
  const scrollerRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;
      const width = (scrollTop / height) * 100;
      if (scrollerRef.current) {
        scrollerRef.current.style.width = `${width}%`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <div ref={scrollerRef} className={styles.scroller}></div>;
};

export default Scroller;
