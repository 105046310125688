import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function AdsController() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // const [imagesLoaded, setImagesLoaded] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleImageLoad = (index) => {
  //   setImagesLoaded((prevState) => ({
  //     ...prevState,
  //     [index]: true,
  //   }));
  // };
  const { t } = useTranslation();

  const imageBasePath = isSmallScreen
  ? `${process.env.PUBLIC_URL}/images/02-tis-air/controllers/details/ads-controller/smallScreens`
  : `${process.env.PUBLIC_URL}/images/02-tis-air/controllers/details/ads-controller`

const modelBasePath = `${process.env.PUBLIC_URL}/images/02-tis-air/controllers/details/ads-controller`

const videoBasePath = `${process.env.PUBLIC_URL}/images/02-tis-air/controllers/details/ads-controller` // Assuming video path

  // const basePath = `${process.env.PUBLIC_URL}/images/02-tis-air/controllers/details/ads-controller`;
  // const smallScreenPath = `${basePath}/smallScreens`;
  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle1`),
      
      top: isSmallScreen ? "15%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "50%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "02.jpg" : "02.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle2`),
      
      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "52%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "50%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "03.jpg" : "03.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle3`),
      
      top: isSmallScreen ? "39%" : "30%",
      left: isSmallScreen ? "32%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "50%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "04.mp4",
      title: "",
      subtitle: t(`airAdsController.subtitle4`),
      
      top: isSmallScreen ? "50%" : "50%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      type: "image",
      src: isSmallScreen ? "05.jpg" : "05.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle5`),
      
      top: isSmallScreen ? "10%" : "40%",
      left: isSmallScreen ? "50%" : "76%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#fff",
    },
    {
      type: "video",
      src: "06.mp4",
      title: "",
      subtitle: t(`airAdsController.subtitle6`),
      
      top: isSmallScreen ? "60%" : "60%",
      left: isSmallScreen ? "23%" : "20%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "50%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "07.jpg" : "07.jpg",
      
      title: t(`airAdsController.title7`),
      subtitle: t(`airAdsController.subtitle7`),
      top: isSmallScreen ? "20%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "75%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "08.mp4",
      title: "",
      subtitle: ``,
      top: isSmallScreen ? "84%" : "30%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "85%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "09.jpg" : "09.jpg",
      title: t(`airAdsController.title9`),
      subtitle: t(`airAdsController.subtitle9`),
      top: isSmallScreen ? "25%" : "50%",
      left: isSmallScreen ? "50%" : "30%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "85%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "10.jpg" : "10.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle10`),
      top: isSmallScreen ? "20%" : "40%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "85%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "11.jpg" : "11.jpg",
      title: "",
      subtitle: t(`airAdsController.subtitle11`),
      top: isSmallScreen ? "82%" : "70%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "80%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      type: "video",
      src: "12.mp4",
      title: "",
      subtitle: isSmallScreen ? "" :t(`airAdsController.subtitle12`),
      
      top: isSmallScreen ? "20%" : "40%",
      left: isSmallScreen ? "50%" : "73%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "85%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
  ];



  const modelsData = [
    {
      id: 1,
      name: "ADS-1D-1Z",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "ADS-2R-2Z",
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: "ADS-3R-3Z",
      image: `${modelBasePath}/models/03.png`,
    },
    {
      id: 4,
      name: "ADS-4CH-0-10V",
      image: `${modelBasePath}/models/04.png`,
    },
  ];
  const textDirection = i18n.language === "ar" ? "rtl" : "ltr"; // تعيين اتجاه الكتابة بناءً على اللغة
  return (
    <div className={styles.container} style={{ direction: textDirection }}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AdsController;


