import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ImageSwitcher from "../../../ImageSwitcher";
import ModelSlider from "../../../ModelSlider";

function MercurySeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const profileImages = [
    "/images/01-tis-bus/panels-details/mercury-series/SwitchImags/black.png",
    "/images/01-tis-bus/panels-details/mercury-series/SwitchImags/white.png",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series`;
  const { t } = useTranslation();
  const modelsData = [
    {
      id: 1,
      name: "MER-2DL-IRR",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "TIS-MER-8G-PB",
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: "TIS-MER-AC4G-PB",
      image: `${modelBasePath}/models/03.png`,
    },
  ];
  return (
    <div className={styles.container}>
      {/* --------01-------*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" , display: "block"}}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/01.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/01.png`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "10%" : "80%", // تعديل top
            left: isSmallScreen ? "50%" : "60%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "70%" : "60%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: "",
            }}
            textAlign="center"
            margin="20px 0"
            padding="10px"
            direction="up"
            zoom={true}
          />
        </div>
        <div style={{ textAlign: "center" }}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "40px",
          }}
        >
          Product Models
        </h1>
        <div>
          <ModelSlider models={modelsData} />
        </div>
      </div>
      </div>

      {/* 02*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%", display: "block" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/02.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/02.png`
          }
          alt=""
        />

        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "83%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "25%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "60%",
            margin: "0",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`mercury.subtitle1`),
            }}
            color="white"
            textAlign="center"
            // margin="20px 0"
            padding="10px"
            direction="down"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "50%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "70%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "100%" : "60%",
          }}
        >
          <div
            style={{
              width: isSmallScreen ? "50%" : "50%",
              margin: isSmallScreen ? "0 auto" : "auto",
            }}
          >
            <ImageSwitcher images={profileImages} />
          </div>
        </div>
      </div>

      {/* 04*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/03.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/03.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "70%" : "48%", // تعديل top
            left: isSmallScreen ? "72%" : "65%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: isSmallScreen ? "center" : "left",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`mercury.subtitle2`),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "center" : "left"}
            margin="20px 0"
            padding="10px"
            direction="right"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 05  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/04.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/04.png`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "70%" : "75%", // تعديل top
            left: isSmallScreen ? "63%" : "80%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "35%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`mercury.subtitle3`),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
            backgroundColor={isSmallScreen ? "" : "#b08968"}
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/05.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/05.png`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "33%" : "20%", // تعديل top
            left: isSmallScreen ? "50%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "60%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`mercury.subtitle4`),
            }}
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%", marginBottom: "100px" }}
      >
        <img
          style={{
            width: isSmallScreen ? "100%" : "50%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "" : "50px",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/smallScreens/06.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mercury-series/06.png`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "22%" : "55%", // تعديل top
            left: isSmallScreen ? "50%" : "70%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`mercury.subtitle5`),
            }}
            textAlign={isSmallScreen ? "center" : "center"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>
    </div>
  );
}

export default MercurySeries;
