import React, { useState } from 'react';
import styles from './MarbleWood.module.css'; // Assuming you're using CSS Modules
import { useTranslation } from 'react-i18next';

const MarbleWood = () => {
  const [t] = useTranslation()
  // State to track the selected material type
  const [selectedType, setSelectedType] = useState('Marble');

  // Images corresponding to each type
  const images = {
    Marble: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/marble/01.jpg`, // path to your marble image
    Wood: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/marble/02.jpg`,
    Aluminum: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/marble/03.jpg`,
  };

  return (
    <div className={styles.container}>
      {/* Left side image that changes */}
      <div className={styles.imageContainer}>
        <img src={images[selectedType]} alt={selectedType} className={styles.image} />
      </div>

      {/* Right side static content */}
      <div className={styles.textContainer}>
        <div className={styles.options}>
          {/* Options to select the type */}
          <p 
            className={selectedType === 'Marble' ? styles.active : ''} 
            onClick={() => setSelectedType('Marble')}
          >Marble</p>
          <p 
            className={selectedType === 'Wood' ? styles.active : ''} 
            onClick={() => setSelectedType('Wood')}
          >Wood</p>
          <p 
            className={selectedType === 'Aluminum' ? styles.active : ''} 
            onClick={() => setSelectedType('Aluminum')}
          >Aluminum</p>
        </div>

        <div className={styles.description}>
          <p>
          {t('venera.subtitle5')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarbleWood;
