import React, { useState } from 'react';
import styles from './Unifi.module.css';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

const USevenProWall = () => {
  const { t } = useTranslation();
  const [showSection, setShowSection] = useState({
    mechanical: false,
    hardware: false,
    software: false,
  });

  const toggleSection = (section) => {
    setShowSection((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: 'easeInOut' } },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };

  return (
    <div className={styles.container}>
      {/* Product Images Section */}
      <section className={styles.imageSection}>
        <h1>{t('u7ProWall.title')}</h1>
        <div className={styles.imageSlider}>
          {[1, 2, 3].map((img, index) => (
            <motion.img
              key={index}
              src={`${process.env.PUBLIC_URL}/images/06-acesspoint/unifi/details/proWall/0${img}.png`}
              alt={t('u7ProWall.imageAlt', { img })}
              className={styles.image}
              initial="hidden"
              animate="visible"
              variants={imageVariants}
              whileHover="hover"
            />
          ))}
        </div>
      </section>

      {/* Product Overview */}
      <section className={styles.overview}>
        <h2>{t('u7ProWall.overview.title')}</h2>
        <p>{t('u7ProWall.overview.description')}</p>
      </section>

      {/* Specifications Section */}
      <section className={styles.specifications}>
        <h2>{t('u7ProWall.specifications.title')}</h2>

        {/* Mechanical Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('mechanical')}>{t('u7ProWall.specifications.mechanical.title')}</h3>
          {showSection.mechanical && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProWall.specifications.mechanical.dimensions.label')}:</strong> 150 x 103 x 36 mm (5.9 x 4.1 x 1.4")</li>
                <li><strong>{t('u7ProWall.specifications.mechanical.weight.label')}:</strong> 580 g (1.3 lb)</li>
                <li><strong>{t('u7ProWall.specifications.mechanical.enclosure.label')}:</strong> Polycarbonate, aluminum</li>
                <li><strong>{t('u7ProWall.specifications.mechanical.mount.label')}:</strong> Aluminum</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* Hardware Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('hardware')}>{t('u7ProWall.specifications.hardware.title')}</h3>
          {showSection.hardware && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProWall.specifications.hardware.networking.label')}:</strong> 1/2.5 GbE RJ45 port</li>
                <li><strong>{t('u7ProWall.specifications.hardware.management.label')}:</strong> Ethernet</li>
                <li><strong>{t('u7ProWall.specifications.hardware.powerMethod.label')}:</strong> PoE+</li>
                <li><strong>{t('u7ProWall.specifications.hardware.maxPowerConsumption.label')}:</strong> 22W</li>
                <li><strong>{t('u7ProWall.specifications.hardware.maxTXPower.label')}:</strong> 2.4 GHz: 22 dBm, 5 GHz: 26 dBm, 6 GHz: 23 dBm</li>
                <li><strong>{t('u7ProWall.specifications.hardware.mimo.label')}:</strong> 2x2 MU-MIMO for 2.4 GHz, 5 GHz, and 6 GHz</li>
                <li><strong>{t('u7ProWall.specifications.hardware.maxDataRate.label')}:</strong> 2.4 GHz: 688 Mbps, 5 GHz: 4.3 Gbps, 6 GHz: 5.7 Gbps</li>
                <li><strong>{t('u7ProWall.specifications.hardware.antennaGain.label')}:</strong> 2.4 GHz: 4 dBi, 5 GHz: 5 dBi, 6 GHz: 6 dBi</li>
                <li><strong>{t('u7ProWall.specifications.hardware.leds.label')}:</strong> White/Blue</li>
                <li><strong>{t('u7ProWall.specifications.hardware.button.label')}:</strong> Factory reset</li>
                <li><strong>{t('u7ProWall.specifications.hardware.mounting.label')}:</strong> Wall (Included), Table stand, flush mount (Optional)</li>
                <li><strong>{t('u7ProWall.specifications.hardware.operatingTemperature.label')}:</strong> -30 to 60° C (-22 to 140° F)</li>
                <li><strong>{t('u7ProWall.specifications.hardware.operatingHumidity.label')}:</strong> 5 to 95% non-condensing</li>
                <li><strong>{t('u7ProWall.specifications.hardware.certifications.label')}:</strong> CE, FCC, IC</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* Software Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('software')}>{t('u7ProWall.specifications.software.title')}</h3>
          {showSection.software && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProWall.specifications.software.wifiStandards.label')}:</strong> 802.11a/b/g/n/ac/ax/be (WiFi 6/6E, WiFi 7)</li>
                <li><strong>{t('u7ProWall.specifications.software.wirelessSecurity.label')}:</strong> WPA-PSK, WPA-Enterprise (WPA/WPA2/WPA3/PPSK)</li>
                <li><strong>{t('u7ProWall.specifications.software.bssid.label')}:</strong> 8 per radio</li>
                <li><strong>{t('u7ProWall.specifications.software.vlan.label')}:</strong> 802.1Q</li>
                <li><strong>{t('u7ProWall.specifications.software.qos.label')}:</strong> Per-user rate limiting</li>
                <li><strong>{t('u7ProWall.specifications.software.guestTrafficIsolation.label')}:</strong> Supported</li>
                <li><strong>{t('u7ProWall.specifications.software.concurrentClients.label')}:</strong> 300+</li>
                <li><strong>{t('u7ProWall.specifications.software.supportedDataRates.label')}:</strong>
                  <ul>
                    <li>802.11a: 6, 9, 12, 18, 24, 36, 48, 54 Mbps</li>
                    <li>802.11b: 1, 2, 5.5, 11 Mbps</li>
                    <li>802.11g: 6, 9, 12, 18, 24, 36, 48, 54 Mbps</li>
                    <li>802.11n: 6.5 Mbps to 300 Mbps</li>
                    <li>802.11ac: Up to 1.7 Gbps</li>
                    <li>802.11ax (WiFi 6/6E): Up to 2.4 Gbps</li>
                    <li>802.11be (WiFi 7): Up to 5.7 Gbps</li>
                  </ul>
                </li>
                <li><strong>{t('u7ProWall.specifications.software.applicationRequirements.label')}:</strong> UniFi Network Version 8.0 or later</li>
              </ul>
            </motion.div>
          )}
        </div>
      </section>
    </div>
  );
};

export default USevenProWall;
