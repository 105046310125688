// Spinner.js

// import React from "react";
// import styles from "./Spinner.module.css"; /

// const Spinner = () => {
//   return (
//     <div className={styles.loaderContainer}>
//       <div className={styles.loader}></div>
//     </div>
//   );
// };

// export default Spinner;


import React from "react";
import SyncLoader from "react-spinners/SyncLoader";

const Spinner = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh' // اجعل الـ Spinner في منتصف الشاشة
    }}>
      <SyncLoader
        color="#aa00d5"
        cssOverride={{
          display: 'block',
          margin: '0 auto', // توسيط تلقائي
        }}
        loading={true}
        margin={2}
        size={15}
        speedMultiplier={1} // سرعة الدوران
      />
    </div>
  );
};

export default Spinner;

