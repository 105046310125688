import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function MarsSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation(); // استدعاء الترجمة
  const imageData = [
    {
      img: "01.jpg",
      title: t("mars.title1"),
      subtitle: t("mars.subtitle1"),
      top: "20%",
      left: "50%",
      textAlign: "center",
      width: "100%",
      direction: "up",
      zoom: true,
      color: "#000",
    },
    {
      img: "02.jpg",
      title: "",
      subtitle: t("mars.subtitle2"),
      top: "30%",
      left: "50%",
      textAlign: "center",
      width: "100%",
      direction: "right",
      fontSize: "20px",
    },
    {
      img: "03.jpg",
      title: "",
      subtitle: t("mars.subtitle3"),
      top: isSmallScreen ? "20%" : "50%",
      left: "50%",
      textAlign: "center",
      width: "80%",
      direction: "right",
      fontSize: "20px",
    },
    {
      img: "04.jpg",
      title: "",
      subtitle: t("mars.subtitle4"),
      top: isSmallScreen ? "15%" : "20%",
      left: "50%",
      textAlign: "center",
      width: isSmallScreen ? "80%" : "35%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "05.jpg",
      title: "",
      subtitle: t("mars.subtitle5"),
      top: "30%",
      left: "50%",
      textAlign: "center",
      width: isSmallScreen ? "80%" : "60%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "06.jpg",
      title: t("mars.title6"),
      subtitle: t("mars.subtitle6"),
      top: isSmallScreen ? "20%" : "45%",
      left: isSmallScreen ? "50%" : "105%",
      textAlign: "left",
      width: "95%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "07.jpg",
      title: "",
      subtitle: t("mars.subtitle7"),
      top: isSmallScreen ? "90%" : "30%",
      left: isSmallScreen ? "40%" : "45%",
      textAlign: "left",
      width: "80%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "08.jpg",
      title: "",
      subtitle: t("mars.subtitle8"),
      top: "15%",
      left: "50%",
      textAlign: "center",
      width: "80%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "09.mp4",
      isVideo: true,
      top: "50%",
      left: "50%",
      textAlign: "center",
      width: "100%",
      direction: "down",
      fontSize: "20px",
    },
    {
      img: "10.jpg",
      title: "",
      subtitle: t("mars.subtitle9"),
      top: isSmallScreen ? "85%" : "50%",
      left: "50%",
      textAlign: "center",
      width: "90%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
    {
      img: "11.jpg",
      title: "",
      subtitle: t("mars.subtitle10"),
      top: isSmallScreen ? "25%" : "30%",
      left: isSmallScreen ? "50%" : "60%",
      textAlign: isSmallScreen ? "center" : "left",
      width: "80%",
      direction: "down",
      fontSize: "20px",
      color: "#000",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/mars-series`;
  const smallScreenPath = `${basePath}/smallScreens`;

  const modelsData = [
    {
      id: 1,
      name: "MRS-12G",
      image: `${basePath}/models/01.png`,
    },
    {
      id: 2,
      name: "MRS-4G",
      image: `${basePath}/models/02.png`,
    },
    {
      id: 3,
      name: "MRS-8G",
      image: `${basePath}/models/03.png`,
    },
    {
      id: 4,
      name: "MRS-AC10G",
      image: `${basePath}/models/04.png`,
    },
  ];

  return (
    <div className={styles.container}>
      {imageData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100vw", display: "block" }}
        >
          {item.isVideo ? (
            <VideoAsGif
              className={styles.videoContainer}
              style={{ width: "100vw" }}
              videoSrc={`${basePath}/${item.img}`}
            />
          ) : (
            <img
              style={{
                width: "100vw",
                marginRight: isSmallScreen ? "0" : "0",
                paddingLeft: isSmallScreen ? "" : "0",
              }}
              src={
                isSmallScreen
                  ? `${smallScreenPath}/${item.img}`
                  : `${basePath}/${item.img}`
              }
              alt=""
              onLoad={() => handleImageLoad(index)}
            />
          )}
          {imagesLoaded[index] && (
            <div
              style={{
                position: "absolute",
                top: item.top,
                left: item.left,
                transform: "translate(-50%, -50%)",
                textAlign: item.textAlign,
                width: item.width,
              }}
            >
              <AnimatedDetailsText
                text={{
                  title: item.title,
                  subtitle: item.subtitle,
                }}
                color={item.color || "#fff"}
                textAlign={item.textAlign}
                margin="20px 0"
                padding="10px"
                direction={item.direction}
                fontSize={item.fontSize || "20px"}
                zoom={item.zoom}
              />
            </div>
          )}
          {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default MarsSeries;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
