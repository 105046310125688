import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'; // تأكد من المسار الصحيح
import ar from './locales/ar.json'; // تأكد من المسار الصحيح

i18n
  .use(initReactI18next) // تمرير i18n إلى react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    lng: 'en', // اللغة الافتراضية
    fallbackLng: 'en', // اللغة الاحتياطية
    interpolation: {
      escapeValue: false, // لا حاجة للهروب في React
    },
  });

export default i18n;
