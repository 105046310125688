import React, { useState } from 'react';
import styles from './Unifi.module.css';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

const USevenOutDoor = () => {
  const { t } = useTranslation();
  const [showSection, setShowSection] = useState({
    mechanical: false,
    hardware: false,
    software: false,
  });

  const toggleSection = (section) => {
    setShowSection((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: 'easeInOut' } },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };

  return (
    <div className={styles.container}>
      {/* Product Images Section */}
      <section className={styles.imageSection}>
        <h1>{t('u7Outdoor.title')}</h1>
        <div className={styles.imageSlider}>
          {[1, 2, 3].map((img, index) => (
            <motion.img
              key={index}
              src={`${process.env.PUBLIC_URL}/images/06-acesspoint/unifi/details/USevenOutdoor/0${img}.png`}
              alt={`U7 Outdoor ${img}`}
              className={styles.image}
              initial="hidden"
              animate="visible"
              variants={imageVariants}
              whileHover="hover"
            />
          ))}
        </div>
      </section>

      {/* Product Overview */}
      <section className={styles.overview}>
        <h2>{t('u7Outdoor.overview.title')}</h2>
        <p>{t('u7Outdoor.overview.details')}</p>
      </section>

      {/* Specifications Section */}
      <section className={styles.specifications}>
        <h2>{t('u7Outdoor.specifications.title')}</h2>

        {/* Mechanical Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('mechanical')}>{t('u7Outdoor.specifications.mechanical.title')}</h3>
          {showSection.mechanical && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.0.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.0.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.1.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.1.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.2.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.2.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.3.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.3.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.4.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.4.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.5.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.5.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.mechanical.details.6.label')}</strong> {t('u7Outdoor.specifications.mechanical.details.6.value')}</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* Hardware Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('hardware')}>{t('u7Outdoor.specifications.hardware.title')}</h3>
          {showSection.hardware && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.0.label')}</strong> {t('u7Outdoor.specifications.hardware.details.0.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.1.label')}</strong> {t('u7Outdoor.specifications.hardware.details.1.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.2.label')}</strong> {t('u7Outdoor.specifications.hardware.details.2.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.3.label')}</strong> {t('u7Outdoor.specifications.hardware.details.3.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.4.label')}</strong> {t('u7Outdoor.specifications.hardware.details.4.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.5.label')}</strong> {t('u7Outdoor.specifications.hardware.details.5.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.6.label')}</strong> {t('u7Outdoor.specifications.hardware.details.6.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.7.label')}</strong> {t('u7Outdoor.specifications.hardware.details.7.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.8.label')}</strong> {t('u7Outdoor.specifications.hardware.details.8.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.9.label')}</strong> {t('u7Outdoor.specifications.hardware.details.9.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.10.label')}</strong> {t('u7Outdoor.specifications.hardware.details.10.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.11.label')}</strong> {t('u7Outdoor.specifications.hardware.details.11.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.hardware.details.12.label')}</strong> {t('u7Outdoor.specifications.hardware.details.12.value')}</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* Software Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('software')}>{t('u7Outdoor.specifications.software.title')}</h3>
          {showSection.software && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7Outdoor.specifications.software.details.0.label')}</strong> {t('u7Outdoor.specifications.software.details.0.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.1.label')}</strong> {t('u7Outdoor.specifications.software.details.1.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.2.label')}</strong> {t('u7Outdoor.specifications.software.details.2.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.3.label')}</strong> {t('u7Outdoor.specifications.software.details.3.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.4.label')}</strong> {t('u7Outdoor.specifications.software.details.4.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.5.label')}</strong> {t('u7Outdoor.specifications.software.details.5.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.6.label')}</strong> {t('u7Outdoor.specifications.software.details.6.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.7.label')}</strong> {t('u7Outdoor.specifications.software.details.7.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.8.label')}</strong> {t('u7Outdoor.specifications.software.details.8.value')}</li>
                <li><strong>{t('u7Outdoor.specifications.software.details.9.label')}</strong> {t('u7Outdoor.specifications.software.details.9.value')}</li>
                {/* <li><strong>{t('u7Outdoor.specifications.software.details.10.label')}</strong> {t('u7Outdoor.specifications.software.details.10.value')}</li> */}
              </ul>
            </motion.div>
          )}
        </div>
      </section>
    </div>
  );
};

export default USevenOutDoor;
