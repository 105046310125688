// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import styles from './ModelSlider.module.css';

// const ModelSlider = ({ models = [] }) => {
//   if (!Array.isArray(models) || models.length === 0) {
//     return <div>No models available</div>;
//   }

//   return (
//     <div className={styles.modelSlider}>
//       <Swiper
//         spaceBetween={30}
//         slidesPerView={3}
//         navigation
//         pagination={{ clickable: true }}
//         autoplay={{ delay: 3000, disableOnInteraction: false }}
//         modules={[Navigation, Pagination, Autoplay]}
//       >
//         {models.map((model) => (
//           <SwiperSlide key={model.id}>
//             <div className={styles.modelCard}>
//               <img src={model.image} alt={model.name} className={styles.modelImage} />
//               <h3 className={styles.modelName}>{model.name}</h3>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default ModelSlider;


import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import styles from './ModelSlider.module.css'; // استيراد CSS Module

const ModelSlider = ({ models = [], customPadding }) => {
  if (!Array.isArray(models) || models.length === 0) {
    return <div>No models available</div>;
  }

  return (
    <div className={styles.modelSlider}>
      <Swiper
        spaceBetween={30}
        slidesPerView={1} // العدد الافتراضي للشرائح
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          // تحديد عدد الشرائح المعروضة حسب حجم الشاشة
          640: {
            slidesPerView: 1, // لشاشة 640 بكسل أو أقل
          },
          768: {
            slidesPerView: 1, // لشاشة 768 بكسل أو أقل
          },
          1024: {
            slidesPerView: 1, // لشاشة 1024 بكسل أو أكثر
          },
        }}
      >
        {models.map((model) => (
          <SwiperSlide key={model.id}>
            <div className={styles.modelCard}>
              <img src={model.image} alt={model.name} className={styles.modelImage} />
              <h3 className={styles.modelName} style={{ padding: customPadding }} >{model.name}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ModelSlider;
