import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
// import ModelSlider from "../../../ModelSlider";

function ZigbeeBusHub() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: t(`beeZigbeeBusHub.subtitle1`),

      top: isSmallScreen ? "75%" : "40%",
      left: isSmallScreen ? "50%" : "40%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "30%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "02.jpg" : "02.jpg",
      title: t(`beeZigbeeBusHub.title2`),
      subtitle: t(`beeZigbeeBusHub.subtitle2`),
      top: isSmallScreen ? "80%" : "50%",
      left: isSmallScreen ? "50%" : "80%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "03.jpg" : "03.jpg",
      title: t(`beeZigbeeBusHub.title3`),
      subtitle: t(`beeZigbeeBusHub.subtitle3`),
      top: isSmallScreen ? "75%" : "40%",
      left: isSmallScreen ? "50%" : "25%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "04.jpg" : "04.jpg",
      title: t(`beeZigbeeBusHub.title4`),
      subtitle: t(`beeZigbeeBusHub.subtitle4`),
      top: isSmallScreen ? "72%" : "45%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "05.jpg" : "05.jpg",
      title: t(`beeZigbeeBusHub.title5`),
      subtitle: t(`beeZigbeeBusHub.subtitle5`),
      top: isSmallScreen ? "80%" : "50%",
      left: isSmallScreen ? "50%" : "20%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      img: isSmallScreen ? "06.jpg" : "06.jpg",
      title: "",
      subtitle: t(`beeZigbeeBusHub.subtitle6`),
      top: isSmallScreen ? "20%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/03-tis-bee/gateway/details/zigbee-bus-hub`;
  const smallScreenPath = `${basePath}/smallScreens`;

  // const modelsData = [
  //   {
  //     id: 1,
  //     name: "TIS-IP-INT-OUT-DB10",
  //     image: `${basePath}/models/01.png`,
  //   },
  // ];

  return (
    <>
      <div className={styles.container}>
        {imageData.map((item, index) => (
          <div
            key={index}
            className={styles.imgContainer}
            style={{ position: "relative", width: "100vw", display: "block" }}
          >
            {item.isVideo ? (
              <VideoAsGif
                className={styles.videoContainer}
                style={{ width: "100vw" }}
                videoSrc={`${basePath}/${item.img}`}
              />
            ) : (
              <img
                style={{
                  width: "100vw",
                  marginRight: isSmallScreen ? "0" : "0",
                  paddingLeft: isSmallScreen ? "0" : "0",
                }}
                src={
                  isSmallScreen
                    ? `${smallScreenPath}/${item.img}`
                    : `${basePath}/${item.img}`
                }
                alt=""
                onLoad={() => handleImageLoad(index)}
              />
            )}
            {imagesLoaded[index] && (
              <div
                style={{
                  position: "absolute",
                  top: item.top,
                  left: item.left,
                  transform: "translate(-50%, -50%)",
                  textAlign: item.textAlign,
                  width: item.width,
                }}
              >
                <AnimatedDetailsText
                  text={{
                    title: item.title,
                    subtitle: item.subtitle,
                  }}
                  color={item.color || "#000"}
                  textAlign={item.textAlign}
                  margin="20px 0"
                  padding="10px"
                  direction={item.direction}
                  fontSize={item.fontSize || "20px"}
                  zoom={item.zoom}
                />
              </div>
            )}
            {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}
            {/* {index === 0 && (
              <div style={{ textAlign: "center" }}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    marginTop: "40px",
                  }}
                >
                  Product Models
                </h1>
                <div>
                  <ModelSlider models={modelsData} />
                </div>
              </div>
            )} */}
          </div>
        ))}
      </div>
    </>
  );
}

export default ZigbeeBusHub;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
