import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../ProductsDetails.module.css";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function LunaSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBasePath = isSmallScreen
    ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/luna-panel-details/smallScreens`
    : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/luna-panel-details`;

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/luna-panel-details`;

  const videoBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/luna-panel-details`; // Assuming video path
  const { t } = useTranslation();
  // const defaultTextAlign = "center";
  const isArabic = i18n.language === "ar";
  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.png",
      title: t("lunaPanel.title1"),
      subtitle: t("lunaPanel.subtitle1"),
      top: isSmallScreen ? "15%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      // textAlign: i18n.language === 'ar' && "left" ? "right" : "center",
      // textAlign: i18n.language === 'ar' ? (defaultTextAlign === 'left' ? 'right' : defaultTextAlign) : defaultTextAlign,
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "02.jpg" : "02.jpg",
      title: t("lunaPanel.title2"),
      subtitle: t("lunaPanel.subtitle2"),
      top: isSmallScreen ? "20%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "video",
      src: "03.mp4",
      title: t("lunaPanel.title3"),
      subtitle: t("lunaPanel.subtitle3"),
      top: isSmallScreen ? "45%" : "50%",
      left: isSmallScreen ? "32%" : "35%",
      // textAlign: "left",
      textAlign: i18n.language === "ar" ? "right" : "left", // قيمة افتراضية باللغة العربية

      width: isSmallScreen ? "45%" : "30%",
      direction: "down",
      color: "#000", // لون النص للفيديو
    },
    {
      type: "image",
      src: isSmallScreen ? "04.jpg" : "04.jpg",
      title: t("lunaPanel.title4"),
      subtitle: t("lunaPanel.subtitle4"),
      top: isSmallScreen ? "82%" : "20%",
      left: isSmallScreen ? "30%" : "60%",
      textAlign: "center",
      width: isSmallScreen ? "50%" : "45%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "video",
      src: "05.mp4",
      title: t("lunaPanel.title5"),
      subtitle: t("lunaPanel.subtitle5"),
      top: isSmallScreen ? "40%" : "19%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "06.jpg" : "06.jpg",
      title: t("lunaPanel.title6"),
      subtitle: t("lunaPanel.subtitle6"),
      top: isSmallScreen ? "80%" : "50%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "30%",
      direction: "up",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "video",
      src: "07.mp4",
      title: t("lunaPanel.title7"),
      subtitle: t("lunaPanel.subtitle7"),
      top: isSmallScreen ? "50%" : "20%",
      left: isSmallScreen ? "70%" : "60%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "08.jpg" : "08.jpg",
      title: t("lunaPanel.title8"),
      subtitle: t("lunaPanel.subtitle8"),
      top: isSmallScreen ? "23%" : "50%",
      left: isSmallScreen ? "50%" : "20%",
      // textAlign: isSmallScreen ? "center" : "left",

      textAlign: isSmallScreen
        ? isArabic
          ? "center"
          : "center"
        : isArabic
        ? "right"
        : "left",

      width: isSmallScreen ? "70%" : "30%",
      direction: "up",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "09.jpg" : "09.jpg",
      title: t("lunaPanel.title9"),
      subtitle: t("lunaPanel.subtitle9"),
      top: isSmallScreen ? "13%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "60%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "video",
      src: "10.mp4",
      title: t("lunaPanel.title10"),
      subtitle: t("lunaPanel.subtitle10"),
      top: isSmallScreen ? "47%" : "50%",
      left: isSmallScreen ? "30%" : "30%",
      // textAlign: "left",
      textAlign: isSmallScreen
        ? isArabic
          ? "right"
          : "left"
        : isArabic
        ? "right"
        : "left",
      width: isSmallScreen ? "50%" : "35%",
      direction: "up",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "11.jpg" : "11.jpg",
      title: t("lunaPanel.title11"),
      subtitle: t("lunaPanel.subtitle11"),
      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "75%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "12.jpg" : "12.jpg",
      title: t("lunaPanel.title12"),
      subtitle: t("lunaPanel.subtitle12"),
      top: isSmallScreen ? "15%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "60%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },

    {
      type: "image",
      src: isSmallScreen ? "13.jpg" : "13.jpg",
      title: t("lunaPanel.title13"),
      subtitle: t("lunaPanel.subtitle13"),
      top: isSmallScreen ? "22%" : "40%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen
        ? isArabic
          ? "center"
          : "center"
        : isArabic
        ? "right"
        : "left",
      width: isSmallScreen ? "80%" : "36%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },

    {
      type: "image",
      src: isSmallScreen ? "14.jpg" : "14.jpg",
      title: t("lunaPanel.title14"),
      subtitle: t("lunaPanel.subtitle14"),
      top: isSmallScreen ? "78%" : "50%",
      left: isSmallScreen ? "50%" : "30%",
      textAlign: isSmallScreen
        ? isArabic
          ? "center"
          : "center"
        : isArabic
        ? "right"
        : "left",
      width: isSmallScreen ? "70%" : "35%",
      direction: "up",
      zoom: true,
      color: isSmallScreen ? "#000" : "#fff",
    },

    {
      type: "image",
      src: isSmallScreen ? "15.jpg" : "15.jpg",
      title: t("lunaPanel.title15"),
      subtitle: t("lunaPanel.subtitle15"),
      top: isSmallScreen ? "22%" : "22%",
      left: isSmallScreen ? "50%" : "25%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "40%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },

    {
      type: "image",
      src: isSmallScreen ? "16.jpg" : "16.jpg",
      title: t("lunaPanel.title16"),
      subtitle: t("lunaPanel.subtitle16"),
      top: isSmallScreen ? "83%" : "22%",
      left: isSmallScreen ? "22%" : "75%",
      // textAlign: isSmallScreen ? "left" : "center",

      textAlign: isSmallScreen
        ? isArabic
          ? "right"
          : "left"
        : isArabic
        ? "center"
        : "center",

      width: isSmallScreen ? "40%" : "35%",
      direction: "up",
      zoom: true,
      color: isSmallScreen ? "#000" : "#fff",
    },

    {
      type: "image",
      src: isSmallScreen ? "17.jpg" : "17.jpg",
      title: t("lunaPanel.title17"),
      subtitle: t("lunaPanel.subtitle17"),
      top: isSmallScreen ? "80%" : "75%",
      left: isSmallScreen ? "50%" : "73%",
      // textAlign: isSmallScreen ? "center" : "left",
      textAlign: isSmallScreen
        ? i18n.language === "ar"
          ? "center"
          : "center"
        : i18n.language === "ar"
        ? "right"
        : "left",

      width: isSmallScreen ? "90%" : "35%",
      direction: "up",
      zoom: true,
      color: "#fff",
    },
  ];

  const modelsData = [
    {
      id: 1,
      name: t("lunamodels.luna9Gangs"), // استخدام الترجمة
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: t("lunamodels.lunaBedSide"), // استخدام الترجمة
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: t("lunamodels.lunaTFT43"), // استخدام الترجمة
      image: `${modelBasePath}/models/03.png`,
    },
  ];

  const textDirection = i18n.language === "ar" ? "rtl" : "ltr"; // تعيين اتجاه الكتابة بناءً على اللغة

  return (
    <div className={styles.container} style={{ direction: textDirection }}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default LunaSeries;
