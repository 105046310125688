import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function AirBusConverter() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",

      subtitle: t(`airBusConverter.subtitle1`),

      top: isSmallScreen ? "15%" : "30%",
      left: isSmallScreen ? "50%" : "35%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "02.jpg" : "02.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle2`),
      top: isSmallScreen ? "20%" : "50%",
      left: isSmallScreen ? "50%" : "60%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "03.jpg" : "03.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle3`),

      top: isSmallScreen ? "22%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "04.jpg" : "04.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle4`),

      top: isSmallScreen ? "22%" : "40%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "05.jpg" : "05.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle5`),

      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "06.jpg" : "06.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle6`),

      top: isSmallScreen ? "18%" : "50%",
      left: isSmallScreen ? "50%" : "23%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "07.jpg" : "07.jpg",
      title: "",
      subtitle: t(`airBusConverter.subtitle7`),

      top: isSmallScreen ? "84%" : "68%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "80%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#fff" : "#fff",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/02-tis-air/Gateways/details/air-bus-converter`;
  const smallScreenPath = `${basePath}/smallScreens`;

  const modelsData = [
    {
      id: 1,
      name: "TIS-AIR-BUS",
      image: `${basePath}/models/01.png`,
    },
    {
      id: 2,
      name: "TIS-AIR-BUS-3W",
      image: `${basePath}/models/02.png`,
    },
  ];

  return (
    <div className={styles.container}>
      {imageData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100vw", display: "block" }}
        >
          {item.isVideo ? (
            <VideoAsGif
              className={styles.videoContainer}
              style={{ width: "100vw" }}
              videoSrc={`${basePath}/${item.img}`}
            />
          ) : (
            <img
              style={{
                width: "100vw",
                marginRight: isSmallScreen ? "0" : "0",
                paddingLeft: isSmallScreen ? "0" : "0",
              }}
              src={
                isSmallScreen
                  ? `${smallScreenPath}/${item.img}`
                  : `${basePath}/${item.img}`
              }
              alt=""
              onLoad={() => handleImageLoad(index)}
            />
          )}
          {imagesLoaded[index] && (
            <div
              style={{
                position: "absolute",
                top: item.top,
                left: item.left,
                transform: "translate(-50%, -50%)",
                textAlign: item.textAlign,
                width: item.width,
              }}
            >
              <AnimatedDetailsText
                text={{
                  title: item.title,
                  subtitle: item.subtitle,
                }}
                color={item.color || "#000"}
                textAlign={item.textAlign}
                margin="20px 0"
                padding="10px"
                direction={item.direction}
                fontSize={item.fontSize || "20px"}
                zoom={item.zoom}
              />
            </div>
          )}
          {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AirBusConverter;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
