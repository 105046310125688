import React from "react";
import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";
import { motion } from "framer-motion";

function EightX() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const basePath = `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/details`;
  const smallScreenPath = `${basePath}/smallScreens`;
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.png" : "01.png",
      title: t("smartManaged.details1.title1"),
      subtitle: isSmallScreen ? "" : t("smartManaged.details1.description1"),
      top: isSmallScreen ? "50%" : "50%",
      left: isSmallScreen ? "25%" : "25%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "40%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "02.png" : "02.png",
      title: t("smartManaged.details2.title2"),
      subtitle: isSmallScreen ? "" : t("smartManaged.details2.description2"),

      top: isSmallScreen ? "10%" : "10%",
      left: isSmallScreen ? "52%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "40%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "03.png" : "03.png",
      title: t("smartManaged.details3.title3"),
      subtitle: isSmallScreen ? "" : t("smartManaged.details3.description3"),

      top: isSmallScreen ? "50%" : "50%",
      left: isSmallScreen ? "25%" : "30%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "40%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "04.png" : "04.png",
      title: t("smartManaged.details4.title4"),
      subtitle: isSmallScreen ? "" : t("smartManaged.details4.description4"),
      top: isSmallScreen ? "50%" : "47%",
      left: isSmallScreen ? "65%" : "65%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "40%" : "50%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "05.png" : "05.png",
      title: t("smartManaged.details5.title5"),
      subtitle: isSmallScreen ? "" : t("smartManaged.details5.description5"),
      top: isSmallScreen ? "50%" : "50%",
      left: isSmallScreen ? "30%" : "25%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "40%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "06.png" : "06.png",
      title: t("smartManaged.details6.title6"),
      subtitle: isSmallScreen
        ? t("smartManaged.details6.description6mobile")
        : t("smartManaged.details6.description6"),
      top: isSmallScreen ? "25%" : "25%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
  ];

  const modelsData = [
    {
      id: 1,
      name: "8 x 1000Base",
      image: `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/01.png`,
    },
    {
      id: 2,
      name: "16 x 1000Base",
      image: `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/02.png`,
    },
    {
      id: 3,
      name: "24 x 1000Base-T",
      image: `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/03.png`,
    },
    {
      id: 4,
      name: "24 x 1000Base",
      image: `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/04.png`,
    },
    {
      id: 5,
      name: "48 x 1000Base-T",
      image: `${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/05.png`,
    },
  ];

  return (
    <>
      <motion.div
        className={styles.productDetails}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className={styles.productImage}
          initial={{ x: -200 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/06-acesspoint/d-link/000.png`}
            alt="Product"
          />
        </motion.div>
        <motion.div
          className={styles.productInfo}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            className={styles.productTitle}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            style={{ color: "red" }}
          >
            {t("smartManaged.productName")} {/* استبدال الاسم بالنص المترجم */}
            <p style={{ fontSize: "20px", color: "#000" }}>
              {t("smartManaged.productDescription")}{" "}
              {/* استبدال الوصف بالنص المترجم */}
            </p>
          </motion.h1>
          <motion.ul className={styles.productFeatures}>
            <motion.li
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("smartManaged.feature1.title")} {/* عنوان الميزة */}
              </span>{" "}
              {t("smartManaged.feature1.description")} {/* وصف الميزة */}
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.6 }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("smartManaged.feature2.title")}
              </span>{" "}
              {t("smartManaged.feature2.description")}
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 0.6 }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("smartManaged.feature3.title")}
              </span>{" "}
              {t("smartManaged.feature3.description")}
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 0.6 }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("smartManaged.feature4.title")}
              </span>{" "}
              {t("smartManaged.feature4.description")}
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.8, duration: 0.6 }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("smartManaged.feature5.title")}
              </span>{" "}
              {t("smartManaged.feature5.description")}
            </motion.li>
          </motion.ul>
        </motion.div>
      </motion.div>
      <div style={{ textAlign: "center" }}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "40px",
          }}
        >
          Type
        </h1>
        <div style={{ marginTop: "30px", marginBottom: "50px" }}>
          <ModelSlider models={modelsData} customPadding="0 0 45px" />
        </div>
      </div>
      <div className={styles.container}>
        {imageData.map((item, index) => (
          <div
            key={index}
            className={styles.imgContainer}
            style={{ position: "relative", width: "100vw", display: "block" }}
          >
            {item.isVideo ? (
              <VideoAsGif
                className={styles.videoContainer}
                style={{ width: "100vw" }}
                videoSrc={
                  isSmallScreen
                    ? `${smallScreenPath}/${item.img}`
                    : `${basePath}/${item.img}`
                }
              />
            ) : (
              <img
                style={{
                  width: "100vw",
                  marginRight: isSmallScreen ? "0" : "0",
                  paddingLeft: isSmallScreen ? "0" : "0",
                }}
                src={
                  isSmallScreen
                    ? `${smallScreenPath}/${item.img}`
                    : `${basePath}/${item.img}`
                }
                alt=""
                onLoad={() => handleImageLoad(index)}
              />
            )}
            {imagesLoaded[index] && (
              <div
                style={{
                  position: "absolute",
                  top: item.top,
                  left: item.left,
                  transform: "translate(-50%, -50%)",
                  textAlign: item.textAlign,
                  width: item.width,
                }}
              >
                <AnimatedDetailsText
                  text={{
                    title: item.title,
                    subtitle: item.subtitle,
                  }}
                  color={item.color || "#000"}
                  textAlign={item.textAlign}
                  margin="20px 0"
                  padding="10px"
                  direction={item.direction}
                  fontSize={item.fontSize || "20px"}
                  zoom={item.zoom}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default EightX;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={`${styles.videoContainer} ${className}`}>
      <video autoPlay loop muted playsInline style={{ width: "100vw" }}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
