import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LanguageSwitcher.module.css"; // استدعاء CSS للـ Language Switcher

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);

    // تغيير اتجاه النص ولكن الحفاظ على الـ scrollbar في اليمين
    document.body.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    document.documentElement.style.direction = 'ltr'; // تثبيت شريط التمرير على اليمين

      // منع إعادة التوجيه
  event.preventDefault();
  };

  // تعيين اللغة الافتراضية عند تحميل التطبيق
  useEffect(() => {
    const currentLang = i18n.language;

    // تغيير اتجاه النص ولكن الحفاظ على الـ scrollbar في اليمين
    document.body.setAttribute('dir', currentLang === 'ar' ? 'rtl' : 'ltr');
    document.documentElement.style.direction = 'ltr'; // تثبيت شريط التمرير على اليمين
  }, [i18n]);

  return (
    <div className={styles.switcherContainer}>
      <select
        className={styles.languageSelect}
        onChange={changeLanguage}
        value={i18n.language}
      >
        <option value="en">En</option>
        <option value="ar">العربية</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;
