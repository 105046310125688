import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import VeneraPhotos from "./VeneraPhotos";
// import ModelSlider from "../../../ModelSlider";

function VeneraZigbeeSwitch() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: ``,
      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "02.jpg" : "02.jpg",
      title: t(`beeveneraZigbeeSwitch.title2`),
      subtitle: t(`beeveneraZigbeeSwitch.subtitle2`),
      top: isSmallScreen ? "15%" : "40%",
      left: isSmallScreen ? "50%" : "27%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "03.jpg" : "03.jpg",
      title: t(`beeveneraZigbeeSwitch.title3`),
      subtitle: t(`beeveneraZigbeeSwitch.subtitle3`),
      top: isSmallScreen ? "16%" : "50%",
      left: isSmallScreen ? "50%" : "78%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "04.jpg" : "04.jpg",
      title: t(`beeveneraZigbeeSwitch.title4`),
      subtitle: t(`beeveneraZigbeeSwitch.subtitle4`),

      top: isSmallScreen ? "15%" : "83%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: "05.mp4",
      isVideo: true,
      title: "",
      subtitle: t(`beeveneraZigbeeSwitch.subtitle5`),

      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    // {
    //   img: isSmallScreen ? "06.jpg" : "06.jpg",
    //   title: t(`beeveneraZigbeeSwitch.title6`),
    //   subtitle: t(`beeveneraZigbeeSwitch.subtitle6`),

    //   top: isSmallScreen ? "15%" : "82%",
    //   left: isSmallScreen ? "50%" : "50%",
    //   textAlign: isSmallScreen ? "center" : "left",
    //   width: isSmallScreen ? "60%" : "45%",
    //   direction: isSmallScreen ? "up" : "down",
    //   zoom: true,
    //   color: isSmallScreen ? "#000" : "#000",
    // },
    {
      img: isSmallScreen ? "07.jpg" : "07.jpg",
      title: t(`beeveneraZigbeeSwitch.title7`),
      subtitle: t(`beeveneraZigbeeSwitch.subtitle7`),
      top: isSmallScreen ? "70%" : "50%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "35%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "08.jpg" : "08.jpg",
      title: t(`beeveneraZigbeeSwitch.title8`),
      subtitle: t(`beeveneraZigbeeSwitch.subtitle8`),

      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#fff" : "#fff",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch`;
  const smallScreenPath = `${basePath}/smallScreens`;

  // const modelsData = [
  //   {
  //     id: 1,
  //     name: "",
  //     image: `${basePath}/models/01.png`,
  //   },

  // ];

  return (
    <div className={styles.container}>
      {imageData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100vw", display: "block" }}
        >
          {item.isVideo ? (
            <VideoAsGif
              className={styles.videoContainer}
              style={{ width: "100vw" }}
              videoSrc={`${basePath}/${item.img}`}
            />
          ) : (
            <img
              style={{
                width: "100vw",
                marginRight: isSmallScreen ? "0" : "0",
                paddingLeft: isSmallScreen ? "0" : "0",
              }}
              src={
                isSmallScreen
                  ? `${smallScreenPath}/${item.img}`
                  : `${basePath}/${item.img}`
              }
              alt=""
              onLoad={() => handleImageLoad(index)}
            />
          )}
          {imagesLoaded[index] && (
            <div
              style={{
                position: "absolute",
                top: item.top,
                left: item.left,
                transform: "translate(-50%, -50%)",
                textAlign: item.textAlign,
                width: item.width,
              }}
            >
              <AnimatedDetailsText
                text={{
                  title: item.title,
                  subtitle: item.subtitle,
                }}
                color={item.color || "#000"}
                textAlign={item.textAlign}
                margin="20px 0"
                padding="10px"
                direction={item.direction}
                fontSize={item.fontSize || "20px"}
                zoom={item.zoom}
              />
            </div>
          )}
          {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}

          {index === 4 && (
            <div>
              {isSmallScreen ? (
                // عرض صورة مع نص في منتصف أعلاها للموبايل
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/smallScreens/06.jpg`}
                    alt="Venera Switch"
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      color: "#fff", // لون النص الأبيض
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // خلفية شبه شفافة
                      padding: "10px 20px",
                      borderRadius: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t(`beeveneraZigbeeSwitch.title6`)}
                  </div>
                </div>
              ) : (
                // عرض المحتوى الكامل على الشاشات الأكبر
                <div
                  className="imgvernaContainer"
                  style={{ position: "relative" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/00.jpg`}
                    alt="Venera Switch"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "30px",
                      left: "0",
                      width: "100%", // عرض الحاوية بالكامل
                      display: "flex", // استخدام Flexbox
                      alignItems: "center", // توسيط عموديًا
                      justifyContent: "space-between", // توزيع العناصر: النص على اليسار والصور على اليمين
                      padding: "0 50px", // إضافة مسافة من الجوانب
                    }}
                  >
                    {/* النصوص على اليسار */}
                    <div
                      style={{
                        maxWidth: "500px",
                        textAlign: "center",
                        marginLeft: "70px",
                      }}
                    >
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {t(`beeveneraZigbeeSwitch.title6`)}
                      </span>
                      <p style={{ color: "#000" }}>
                        {t(`beeveneraZigbeeSwitch.subtitle6`)}
                      </p>
                    </div>

                    {/* الصور على اليمين */}
                    <div style={{ margin: "auto 120px auto 0" }}>
                      <VeneraPhotos />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default VeneraZigbeeSwitch;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
