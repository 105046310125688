import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
//import ModelSlider from "../../../ModelSlider";

function AvIpMatrix() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: ``,
      top: isSmallScreen ? "30%" : "50%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      img: isSmallScreen ? "02.jpg" : "02.jpg",
      title: t(`audioAv.title2`),
      subtitle: t(`audioAv.subtitle2`),
      top: isSmallScreen ? "30%" : "45%",
      left: isSmallScreen ? "50%" : "20%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "50%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "03.jpg" : "03.jpg",
      title: t(`audioAv.title3`),
      subtitle: t(`audioAv.subtitle3`),
      top: isSmallScreen ? "25%" : "50%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "04.jpg" : "04.jpg",
      title: t(`audioAv.title4`),
      subtitle: t(`audioAv.subtitle4`),
      top: isSmallScreen ? "75%" : "40%",
      left: isSmallScreen ? "50%" : "25%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "05.jpg" : "05.jpg",
      title: t(`audioAv.title5`),
      subtitle: t(`audioAv.subtitle5`),
      top: isSmallScreen ? "70%" : "50%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      img: isSmallScreen ? "06.jpg" : "06.jpg",
      title: t(`audioAv.title6`),
      subtitle: t(`audioAv.subtitle6`),
      top: isSmallScreen ? "17%" : "40%",
      left: isSmallScreen ? "50%" : "23%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      img: isSmallScreen ? "07.jpg" : "07.jpg",
      title: t(`audioAv.title7`),
      subtitle: t(`audioAv.subtitle7`),
      top: isSmallScreen ? "25%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "80%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/audio-video-details/av-matrix`;
  const smallScreenPath = `${basePath}/smallScreens`;
  // const modelsData = [
  //   {
  //     id: 1,
  //     name: "TIS-IP-INT-OUT-DB10",
  //     image: `${basePath}/models/01.png`,
  //   },
  // ];

  return (
    <div className={styles.container}>
      {imageData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100vw", display: "block" }}
        >
          {item.isVideo ? (
            <VideoAsGif
              className={styles.videoContainer}
              style={{ width: "100vw" }}
              videoSrc={
                `${basePath}/${item.img}`
              }
            />
          ) : (
            <img
              style={{
                width: "100vw",
                marginRight: isSmallScreen ? "0" : "0",
                paddingLeft: isSmallScreen ? "0" : "0",
              }}
              src={
                isSmallScreen
                  ? `${smallScreenPath}/${item.img}`
                  : `${basePath}/${item.img}`
              }
              alt=""
              onLoad={() => handleImageLoad(index)}
            />
          )}
          {imagesLoaded[index] && (
            <div
              style={{
                position: "absolute",
                top: item.top,
                left: item.left,
                transform: "translate(-50%, -50%)",
                textAlign: item.textAlign,
                width: item.width,
              }}
            >
              <AnimatedDetailsText
                text={{
                  title: item.title,
                  subtitle: item.subtitle,
                }}
                color={item.color || "#000"}
                textAlign={item.textAlign}
                margin="20px 0"
                padding="10px"
                direction={item.direction}
                fontSize={item.fontSize || "20px"}
                zoom={item.zoom}
              />
            </div>
          )}
          {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}
          {/* {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1 style={{ textAlign: "center", fontSize: "20px",marginTop: "40px" }}>Product Models</h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )} */}
        </div>
      ))}
    </div>
  );
}

export default AvIpMatrix;

const VideoAsGif = ({ videoSrc }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
