import React, { useState } from 'react'; // استيراد useState
import styles from './ClickSlider.module.css'; // استخدام CSS Modules
import { useTranslation } from 'react-i18next';

const ClickSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // حالة لتخزين الشريحة الحالية
  const { t } = useTranslation();

  const slides = [
    {
      image: `${process.env.PUBLIC_URL}/images/01-tis-bus/hotel-interface-details/click-series/ImageSwitch/01.jpg`,
      text:  t(`hotelClick.subtitle5`)
    },
    {
      image: `${process.env.PUBLIC_URL}/images/01-tis-bus/hotel-interface-details/click-series/ImageSwitch/02.jpg`,
      text: t(`hotelClick.subtitle5`)
    },
    {
      image: `${process.env.PUBLIC_URL}/images/01-tis-bus/hotel-interface-details/click-series/ImageSwitch/03.jpg`,
      text: t(`hotelClick.subtitle5`)
    },
  ];

  // دالة للتبديل إلى الشريحة التالية عند الضغط
  const handleClick = () => {
    // تحديث الشريحة الحالية إلى الشريحة التالية
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  return (
    <div className={styles.sliderContainer} style={{width:"100%"}}>
      <div className={styles.slide} onClick={handleClick} style={{display:"flex"}}>  {/* إضافة حدث click */}
        <img 
          src={slides[currentSlide].image} 
          alt={`Slide ${currentSlide + 1}`} 
          className={ styles.image } 
          style={{cursor: "pointer", width: "100vw"}}
        />
        <p style={{color: "#000", textAlign: "center", width: "20%"}} className={styles.text}>{slides[currentSlide].text}</p>
      </div>
    </div>
  );
};

export default ClickSlider;
