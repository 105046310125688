import React from "react";
import { motion } from "framer-motion";
import styles from "../../access.module.css";
import { useTranslation } from "react-i18next";

const SgThreeM = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/06-acesspoint/tp-link/details/sgThree/models/01.jpg`}
          alt={t("sg3218xp.title")} // استخدم العنوان كمحتوى بديل
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          style={{ color: "red" }}
        >
          {t("sg3218xp.title")}
          <p style={{ fontSize: "20px", color: "#000" }}>
            {t("sg3218xp.description")}
          </p>
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature1.title")}
            </span>{" "}
            {t("sg3218xp.features.feature1.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature2.title")}
            </span>{" "}
            {t("sg3218xp.features.feature2.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.8, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature3.title")}
            </span>{" "}
            {t("sg3218xp.features.feature3.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature4.title")}
            </span>{" "}
            {t("sg3218xp.features.feature4.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature5.title")}
            </span>{" "}
            {t("sg3218xp.features.feature5.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.3, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature6.title")}
            </span>{" "}
            {t("sg3218xp.features.feature6.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature7.title")}
            </span>{" "}
            {t("sg3218xp.features.feature7.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("sg3218xp.features.feature8.title")}
            </span>{" "}
            {t("sg3218xp.features.feature8.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default SgThreeM;
