import React, { useState } from 'react';
import styles from './Unifi.module.css';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

const USevenProMax = () => {
  const { t } = useTranslation(); // استخدام الترجمة
  const [showSection, setShowSection] = useState({
    mechanical: false,
    hardware: false,
    wifi: false,
  });

  const toggleSection = (section) => {
    setShowSection((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: 'easeInOut' } },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };

  return (
    <div className={styles.container}>
      {/* Product Images Section */}
      <section className={styles.imageSection}>
        <h1>{t('u7ProMax.title')}</h1>
        <div className={styles.imageSlider}>
          {[1, 2, 3, 4].map((img, index) => (
            <motion.img
              key={index}
              src={`${process.env.PUBLIC_URL}/images/06-acesspoint/unifi/details/proMax/0${img}.png`}
              alt={t('u7ProMax.imageAlt', { img })}
              className={styles.image}
              initial="hidden"
              animate="visible"
              variants={imageVariants}
              whileHover="hover"
            />
          ))}
        </div>
      </section>

      {/* Product Overview */}
      <section className={styles.overview}>
        <h2>{t('u7ProMax.overviewTitle')}</h2>
        <p>{t('u7ProMax.overviewDescription')}</p>
      </section>

      {/* Specifications Section */}
      <section className={styles.specifications}>
        <h2>{t('u7ProMax.specificationsTitle')}</h2>

        {/* Mechanical Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('mechanical')}>
            {t('u7ProMax.mechanicalTitle')}
          </h3>
          {showSection.mechanical && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProMax.dimensions')}:</strong> Ø206 x 46 mm (Ø8.1 x 1.8")</li>
                <li><strong>{t('u7ProMax.weight')}:</strong> 680 g (1.5 lb)</li>
                <li><strong>{t('u7ProMax.enclosureMaterial')}:</strong> {t('u7ProMax.polycarbonateAluminum')}</li>
                <li><strong>{t('u7ProMax.mountMaterial')}:</strong> {t('u7ProMax.mountMaterials')}</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* Hardware Specifications */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('hardware')}>
            {t('u7ProMax.hardwareTitle')}
          </h3>
          {showSection.hardware && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProMax.networkInterface')}:</strong> 1/2.5 GbE RJ45 port</li>
                <li><strong>{t('u7ProMax.powerMethod')}:</strong> PoE+</li>
                <li><strong>{t('u7ProMax.maxPower')}:</strong> 25W</li>
                <li><strong>{t('u7ProMax.maxTXPower')}:</strong> 2.4 GHz: 23 dBm, 5 GHz: 29 dBm, 6 GHz: 23 dBm</li>
                <li><strong>{t('u7ProMax.maxDataRate')}:</strong> 2.4 GHz: 688 Mbps, 5 GHz: 8.6 Gbps, 6 GHz: 5.7 Gbps</li>
              </ul>
            </motion.div>
          )}
        </div>

        {/* WiFi and Software Information */}
        <div className={styles.specSection}>
          <h3 onClick={() => toggleSection('wifi')}>
            {t('u7ProMax.wifiTitle')}
          </h3>
          {showSection.wifi && (
            <motion.div
              className={styles.specDetails}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <ul>
                <li><strong>{t('u7ProMax.wifiStandards')}:</strong> {t('u7ProMax.wifiStandardsList')}</li>
                <li><strong>{t('u7ProMax.security')}:</strong> {t('u7ProMax.securityList')}</li>
                <li><strong>{t('u7ProMax.guestIsolation')}:</strong> {t('u7ProMax.guestIsolationStatus')}</li>
                <li><strong>{t('u7ProMax.concurrentClients')}:</strong> 500+</li>
              </ul>
            </motion.div>
          )}
        </div>
      </section>
    </div>
  );
};

export default USevenProMax;
