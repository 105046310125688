import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styles from "../hik.module.css";

const FourMWdr = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4mpOutdoorWdr/01.png`}
          alt={t("fourMPWdr.productImageAlt")}
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          {t("fourMPWdr.title")}
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          {Array.from({ length: 5 }, (_, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 + index * 0.2, duration: 0.6 }}
            >
              {t(`fourMPWdr.feature${index + 1}`)}
            </motion.li>
          ))}
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <div style={{ width: "100%", display: "flex", gap: "15px", justifyContent: "center" }}>
              {Array.from({ length: 6 }, (_, index) => (
                <img
                  key={index}
                  style={{ width: "40px" }}
                  src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4mpOutdoorWdr/icons/0${index + 1}.png`}
                  alt="" // لا حاجة لوصف النص البديل
                />
              ))}
            </div>
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default FourMWdr;
