import React from "react";
import { motion } from "framer-motion";
import styles from "../../access.module.css";
import { useTranslation } from "react-i18next";

const EapSexOutdoor = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/06-acesspoint/tp-link/details/eapSexOutdoor/models/01.png`}
          alt={t("eap655Wall.imageAlt")} // Use a translation key for the alt text
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          style={{ color: "red" }}
        >
          {t("eap655Wall.title")}
          <p style={{ fontSize: "20px", color: "#000" }}>
            {t("eap655Wall.subtitle")}
          </p>
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.superiorSpeeds.title")}:
            </span>{" "}
            {t("eap655Wall.features.superiorSpeeds.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.higherEfficiency.title")}:
            </span>{" "}
            {t("eap655Wall.features.higherEfficiency.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.8, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.bandwidth.title")}:
            </span>{" "}
            {t("eap655Wall.features.bandwidth.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.longRange.title")}:
            </span>{" "}
            {t("eap655Wall.features.longRange.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.meshTechnology.title")}:
            </span>{" "}
            {t("eap655Wall.features.meshTechnology.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.3, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.seamlessRoaming.title")}:
            </span>{" "}
            {t("eap655Wall.features.seamlessRoaming.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.poePowered.title")}:
            </span>{" "}
            {t("eap655Wall.features.poePowered.description")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          ></motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("eap655Wall.features.cloudManagement.title")}:
            </span>{" "}
            {t("eap655Wall.features.cloudManagement.description")}
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default EapSexOutdoor;
