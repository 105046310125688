import React, { useState } from "react";
import styles from "./SolPhotosSwitch.module.css"; // استيراد CSS Module
import { useTranslation } from "react-i18next";

const types = [
  {
    id: 1,
    label: "Wood",
    img: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/photos/01.png`,
    icon: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/icons/01.png`,
  },
  {
    id: 2,
    label: "Gold",
    img: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/photos/02.png`,
    icon: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/icons/02.png`,
  },
  {
    id: 3,
    label: "Silver",
    img: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/photos/03.png`,
    icon: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/icons/03.png`,
  },
  {
    id: 4,
    label: "Fabric",
    img: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/photos/04.png`,
    icon: `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/icons/04.png`,
  },
];

function SolPhotosSwitch() {
  const [selectedImage, setSelectedImage] = useState(types[0].img); // الصورة المحددة
  const [activeId, setActiveId] = useState(types[0].id); // الحالة النشطة

  const handleImageChange = (img, id) => {
    setSelectedImage(img); // تحديث الصورة المحددة
    setActiveId(id); // تحديث الحالة النشطة
  };
const {t} = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <p className={styles.description}>
          {t(`sol.subtitle3`)}
        </p>
        <div
          className={styles.typeSelector}
          
        >
          {types.map((type) => (
            <div
              style={{ textAlign: "center", margin: "0 auto" }}
              key={type.id}
              className={`${styles.typeCircle} ${
                activeId === type.id ? styles.active : ""
              }`}
              onClick={() => handleImageChange(type.img, type.id)} // استدعاء الدالة لتحديث الصورة
            >
              <img
                src={type.icon}
                alt={type.label}
                style={{ width: "50px", height: "50px", textAlign: "center" }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.rightSection}>
        <img
          src={selectedImage}
          alt="Selected Cover"
          className={styles.coverImage}
          style={{ width: "700px" }} // عرض الصورة بحجم ثابت
        />
      </div>
    </div>
  );
}

export default SolPhotosSwitch;
