import React, { useState } from 'react';
import styles from './GangSwitch.module.css';
import { useTranslation } from 'react-i18next';

const gangImages = {
  '2Gang': `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/gang/01.jpg`,
  '3Gang': `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/gang/02.jpg`,
  '4Gang': `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/gang/03.jpg`,
  '6Gang': `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/gang/04.jpg`,
  'A/C': `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/ven-bus/gang/05.jpg`,
};

const GangSwitch = () => {
  const [selectedGang, setSelectedGang] = useState('2Gang');
  const {t} = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <p>
          {t('venera.subtitle2')}
        </p>
        <div className={styles.buttons}>
          {Object.keys(gangImages).map((gang) => (
            <button
              key={gang}
              className={`${styles.gangButton} ${
                selectedGang === gang ? styles.active : ''
              }`}
              onClick={() => setSelectedGang(gang)}
            >
              {gang}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.rightSection}>
        <img
          src={gangImages[selectedGang]}
          alt={selectedGang}
          className={styles.gangImage}
        />
      </div>
    </div>
  );
};

export default GangSwitch;
