import React, { useState } from 'react';
import styles from './VeneraPhotos.module.css';

const images = [
  {
    icon: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/icons/01.png`,
    fullImage: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/models/01.png`,
    alt: 'خشب 1',
  },
  {
    icon: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/icons/02.png`,
    fullImage: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/models/02.png`,
    alt: 'رخام 1',
  },
  {
    icon: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/icons/03.png`,
    fullImage: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/models/03.png`,
    alt: 'رخام أسود',
  },
  {
    icon: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/icons/04.png`,
    fullImage: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/models/04.png`,
    alt: 'رخام أسود',
  },
  {
    icon: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/icons/05.png`,
    fullImage: `${process.env.PUBLIC_URL}/images/03-tis-bee/wall-switch/details/venera-zigbee-switch/models/05.png`,
    alt: 'رخام أسود',
  },
];

function VeneraPhotos() {
  const [selectedImage, setSelectedImage] = useState(images[0].fullImage);

  const handleImageClick = (image) => {
    setSelectedImage(image.fullImage);
  };

  return (
    <div className={styles.containerverna}>
      {/* الصور المصغرة / الأيقونات */}
      <div className={styles.thumbnails}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image.icon}
            alt={image.alt}
            className={`${styles.thumbnail} ${
              selectedImage === image.fullImage ? styles.active : ''
            }`}
            onClick={ () => handleImageClick(image) }
            style={{width: "50px"}}
          />
        ))}
      </div>

      {/* عرض الصورة الكبيرة */}
      <div>
        <img
          src={selectedImage}
          alt="الصورة المعروضة"
          className={ styles.mainImage }
          style={{width: "400px"}}
        />
      </div>
    </div>
  );
}

export default VeneraPhotos;
