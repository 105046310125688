import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styles from "../hik.module.css";

const FourKWdr = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/01.png`}
          alt={t("fourKWdr.productImageAlt")}
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          {t("fourKWdr.title")}
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            {t("fourKWdr.feature1")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            {t("fourKWdr.feature2")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.8, duration: 0.6 }}
          >
            {t("fourKWdr.feature3")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1, duration: 0.6 }}
          >
            {t("fourKWdr.feature4")}
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2, duration: 0.6 }}
          >
            {t("fourKWdr.feature5")}
          </motion.li>

          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.3, duration: 0.6 }}
          >
            <div style={{ width: "100%", display: "flex", gap: "15px", justifyContent: "center" }}>
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/01.png`}
                alt=""
              />
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/02.png`}
                alt=""
              />
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/03.png`}
                alt=""
              />
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/04.png`}
                alt=""
              />
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/05.png`}
                alt=""
              />
              <img
                style={{ width: "40px" }}
                src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/out/details/4k/icons/06.png`}
                alt=""
              />
            </div>
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default FourKWdr;
