import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function ProjectorLift() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBasePath = isSmallScreen
    ? `${process.env.PUBLIC_URL}/images/01-tis-bus/motors-details/projector-lift/smallScreens`
    : `${process.env.PUBLIC_URL}/images/01-tis-bus/motors-details/projector-lift`;

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/motors-details/projector-lift`;

  const videoBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/motors-details/projector-lift`; // Assuming video path
  const { t } = useTranslation();
  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: ``,
      subtitle: t(`motorProjector.subtitle1`),
      top: isSmallScreen ? "15%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },

    {
      type: "video",
      src: "02.mp4",
      title: t(`motorProjector.title2`),
      subtitle: isSmallScreen ? "" : t(`motorProjector.subtitle2`),
      top: isSmallScreen ? "71%" : "70%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: "center",
      width: isSmallScreen ? "90%" : "50%",
      direction: "down",
      color: "#000", // لون النص للفيديو
    },
    {
      type: "image",
      src: isSmallScreen ? "03.jpg" : "03.jpg",
      title: ``,
      subtitle: t(`motorProjector.subtitle3`),
      top: isSmallScreen ? "17%" : "50%",
      left: isSmallScreen ? "50%" : "80%",
      textAlign: isSmallScreen?"center" : "left",
      width: isSmallScreen ? "70%" : "30%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
  ];

  const modelsData = [
    {
      id: 1,
      name: "TIS-PRJ-LFT-15K-130",
      image: `${modelBasePath}/models/01.png`,
    },
  ];
  return (
    <div className={styles.container}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProjectorLift;
