import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Importing WhatsApp icon from React Icons
import styles from './WhatsappPopup.module.css'; // Importing CSS Module for styling

const WhatsappPopup = () => {
  const handleClick = () => {
    const whatsappURL = 'https://wa.me/971589445296?text=Hello! I need some assistance.'; 
    // Replace 1234567890 with your WhatsApp number
    // The 'text' query parameter can be customized for a pre-filled message
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className={styles.whatsappPopup} onClick={handleClick}>
      <FaWhatsapp className={styles.whatsappIcon} />
      <span>Chat with us</span>
    </div>
  );
};

export default WhatsappPopup;
