import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";
import SolPhotosSwitch from "./SolPhotosSwitch";

function SolSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series`;

  const modelsNames = [
    "SOL-BEL-SW-W",
    "SOL-BLK-A",
    "SOL-BLK-B",
    "SOL-BLK-G",
    "SOL-BLK-W",
    "SOL-EAR-JK-A",
    "SOL-EAR-JK-B",
    "SOL-EAR-JK-G",
    "SOL-EAR-JK-W",
    "SOL-FBLK-A",
    "SOL-FBLK-B",
    "SOL-FBLK-G",
    "SOL-FBLK-W",
    "SOL-HDMI-B",
    "SOL-HDMI-W",
    "SOL-HTR-20A-A",
    "SOL-HTR-20A-B",
    "SOL-HTR-20A-G",
    "SOL-HTR-20A-W",
    "SOL-NET-A",
    "SOL-NET-B",
    "SOL-NET-G",
    "SOL-NET-W",
    "SOL-PLG-UK-S-A",
    "SOL-PLG-UK-S-B",
    "SOL-PLG-UK-S-G",
    "SOL-PLG-UK-S-W",
    "SOL-PLG-UNV-A",
    "SOL-PLG-UNV-B",
    "SOL-PLG-UNV-G",
    "SOL-PLG-UNV-W",
    "SOL-RCA-AV-A",
    "SOL-RCA-AV-B",
    "SOL-RCA-AV-G",
    "SOL-RCA-AV-W",
    "SOL-SAT-A",
    "SOL-SAT-B",
    "SOL-SAT-G",
    "SOL-SAT-W",
    "SOL-SHV-W",
    "SOL-SPK-TRM-A",
    "SOL-SPK-TRM-B",
    "SOL-SPK-TRM-G",
    "SOL-SPK-TRM-W",
    "SOL-SWT-1G-A",
    "SOL-SWT-1G-B",
    "SOL-SWT-1G-C",
    "SOL-SWT-1G-W",
    "SOL-SWT-2G-A",
    "SOL-SWT-2G-B",
    "SOL-SWT-2G-C",
    "SOL-SWT-2G-W",
    "SOL-SWT-3G-A",
    "SOL-SWT-3G-B",
    "SOL-SWT-3G-C",
    "SOL-SWT-3G-W",
    "SOL-VRD-HTU-A",
    "SOL-VRD-HTU-B",
    "SOL-VRD-HTU-G",
    "SOL-VRD-HTU-W",
    "TIS-SOL-3G",
    "TIS-SOL-TFT",
  ];
  const { t } = useTranslation();
  const isArabic = i18n.language === "ar";
  const modelsData = modelsNames.map((modelName, index) => ({
    id: index + 1, // Auto-generate id based on index
    name: modelName,
    image: `${modelBasePath}/models/${index + 1}.png`, // Auto-generate image path based on index
  }));

  return (
    <div className={styles.container}>
      {/* --------01-------*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%", display: "block" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/01.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/01.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "10%" : "80%", // تعديل top
            left: isSmallScreen ? "50%" : "60%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "70%" : "60%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: "",
            }}
            textAlign="center"
            margin="20px 0"
            padding="10px"
            direction="up"
            zoom={true}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            Product Models
          </h1>
          <div>
            <ModelSlider customPadding="0 0 90px" models={modelsData} />
          </div>
        </div>
      </div>

      {/* 02*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/02.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/02.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "16%" : "20%", // تعديل top
            left: isSmallScreen ? "55%" : "50%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "60%",
            margin: "0",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`sol.subtitle1`),
            }}
            color="#000"
            textAlign="left"
            // margin="20px 0"
            padding="10px"
            direction="down"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "50%" : "50%", // تعديل top
            left: isSmallScreen ? "50%" : "70%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "left",
            width: isSmallScreen ? "100%" : "60%",
          }}
        >
          <div
            style={{
              width: isSmallScreen ? "80%" : "50%",
              margin: isSmallScreen ? "0 auto" : "auto",
            }}
          ></div>
        </div>
      </div>

      {/* 04*/}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/03.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/03.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "75%" : "38%", // تعديل top

            left: isSmallScreen
              ? isArabic
                ? "70%"
                : "70%"
              : isArabic
              ? "47%"
              : "115%",

            transform: "translate(-50%, -50%)",
            textAlign: isSmallScreen
              ? isArabic
                ? "center"
                : "center"
              : isArabic
              ? "right"
              : "left",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`sol.subtitle2`),
            }}
            color="#000"
            textAlign={
              isSmallScreen
                ? isArabic
                  ? "center"
                  : "center"
                : isArabic
                ? "right"
                : "left"
            }
            margin="20px 0"
            padding="10px"
            direction="right"
            fontSize="20px"
          />
        </div>
      </div>

        <SolPhotosSwitch />

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{ width: "100%" }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/05.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/05.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "92%" : "45%", // تعديل top
            left: isSmallScreen ? "72%" : "97%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "60%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`sol.subtitle4`),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "left" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "0" : "0",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/06.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/06.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "88%" : "47%", // تعديل top
            left: isSmallScreen ? "75%" : "55%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`sol.subtitle5`),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "left" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

      {/* 06  */}
      <div
        className={styles.imgContainer}
        style={{ position: "relative", width: "100vw" }}
      >
        <img
          style={{
            width: "100%",
            marginRight: isSmallScreen ? "auto" : "auto",
            paddingLeft: isSmallScreen ? "0" : "0",
          }}
          src={
            isSmallScreen
              ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/smallScreens/07.jpg`
              : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/sol-series/07.jpg`
          }
          alt=""
        />
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "78%" : "55%", // تعديل top
            left: isSmallScreen ? "75%" : "47%", // تعديل left
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: isSmallScreen ? "80%" : "80%",
          }}
        >
          <AnimatedDetailsText
            text={{
              title: "",
              subtitle: t(`sol.subtitle6`),
            }}
            color="#fff"
            textAlign={isSmallScreen ? "left" : "left"}
            margin="20px 0 100px 0"
            padding="10px"
            direction="down"
            fontSize="20px"
          />
        </div>
      </div>

    </div>
  );
}

export default SolSeries;

// const VideoAsGif = ({ videoSrc, className }) => {
//   return (
//     <div className={`${styles.videoContainer} ${className}`}>
//       <video autoPlay loop muted playsInline>
//         <source src={videoSrc} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//     </div>
//   );
// };
