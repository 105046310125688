import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../ProductsDetails.module.css";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function Titan() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBasePath = isSmallScreen
    ? `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/titan/smallScreens`
    : `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/titan`;

  const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/titan`;

  const videoBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/panels-details/titan`; // Assuming video path
  const { t } = useTranslation();
  const isArabic = i18n.language === "ar";
  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: t(`titanPanel.title1`),
      subtitle: t(`titanPanel.subtitle1`),
      top: isSmallScreen ? "28%" : "10%",
      left: "50%",
      textAlign: "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "02.jpg" : "02.jpg",
      title: t(`titanPanel.title2`),
      subtitle:  t(`titanPanel.subtitle2`),
      top: "70%",
      left: isSmallScreen ? "65%" : "70%",
      textAlign: "left",
      width: isSmallScreen ? "45%" : "60%",
      direction: "down",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "03.jpg" : "03.jpg",
      title:t(`titanPanel.title3`),
      subtitle:  t(`titanPanel.subtitle3`),
      top: isSmallScreen ? "20%" : "30%",
      left: isSmallScreen ? "50%" : "70%",
      textAlign: isSmallScreen
        ? isArabic
          ? "center"
          : "center"
        : isArabic
        ? "right"
        : "left",

      width: isSmallScreen ? "80%" : "60%",
      direction: "right",
      color: "#000", // لون النص للفيديو
    },
    {
      type: "image",
      src: isSmallScreen ? "04.jpg" : "04.jpg",
      title: t(`titanPanel.title4`),
      subtitle:  t(`titanPanel.subtitle4`),
      top: "75%",
      left: "50%",
      textAlign: "center",
      width: "80%",
      direction: "right",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: "05.jpg",
      title: t(`titanPanel.title5`),
      subtitle:  t(`titanPanel.subtitle5`),
      top: isSmallScreen ? "20%" : "80%",
      left: "50%",
      textAlign: "center",
      width: "80%",
      direction: "down",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: "06.jpg",
      title: t(`titanPanel.title6`),
      subtitle: t(`titanPanel.subtitle6`),
      top: isSmallScreen ? "85%" : "50%",
      left: isSmallScreen ? "50%" : "35%",
      textAlign: isSmallScreen? "center": "left",
      width: isSmallScreen ? "80%" : "60%",
      direction: "left",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: "07.jpg",
      title:t(`titanPanel.title7`),
      subtitle:  t(`titanPanel.subtitle7`),
      top: isSmallScreen ? "22%" : "25%",
      left: isSmallScreen ? "40%" : "82%",
      textAlign: "left",
      width: isSmallScreen ? "65%" : "35%",
      direction: "right",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "08.jpg" : "08.jpg",
      title: t(`titanPanel.title8`),
      subtitle:  t(`titanPanel.subtitle8`),
      top: isSmallScreen ? "23%" : "43%",
      left: isSmallScreen ? "50%" : "70%",
      textAlign: "center",
      width: isSmallScreen ? "90%" : "80%",
      direction: "right",
      zoom: true,
      color: "#fff", // تحديد لون النص
    },
    {
      type: "image",
      src: isSmallScreen ? "09.jpg" : "09.jpg",
      title:t(`titanPanel.title9`),
      subtitle:  t(`titanPanel.subtitle9`),
      top: isSmallScreen ? "82%" : "30%",
      left: isSmallScreen ? "50%" : "100%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "60%",
      direction: "up",
      zoom: true,
      color: "#000", // تحديد لون النص
    },
    {
      type: "image",
      src: "10.jpg",
      title: t(`titanPanel.title10`),
      subtitle:  t(`titanPanel.subtitle10`),
      top: isSmallScreen ? "80%" : "16%",
      left: isSmallScreen ? "58%" : "37%",
      textAlign: "left",
      width: isSmallScreen ? "80%" : "60%",
      direction: "down",
      zoom: true,
      color: "#000",
    },
  ];

  const modelsData = [
    {
      id: 1,
      name: "TIT-2G-BUS-K",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "TIT-2G-BUS-S",
      image: `${modelBasePath}/models/02.png`,
    },
    {
      id: 3,
      name: "TIT-3G-BUS-K",
      image: `${modelBasePath}/models/03.png`,
    },
    {
      id: 4,
      name: "TIT-3G-BUS-S",
      image: `${modelBasePath}/models/04.png`,
    },
    {
      id: 5,
      name: "TIT-4G-BUS-K",
      image: `${modelBasePath}/models/05.png`,
    },
    {
      id: 6,
      name: "TIT-4G-BUS-S",
      image: `${modelBasePath}/models/06.png`,
    },
    {
      id: 7,
      name: "TIT-TFT-BUS-K",
      image: `${modelBasePath}/models/07.png`,
    },
    {
      id: 8,
      name: "TIT-TFT-BUS-K-3R",
      image: `${modelBasePath}/models/08.png`,
    },
    {
      id: 9,
      name: "TIT-TFT-BUS-S",
      image: `${modelBasePath}/models/09.png`,
    },
    {
      id: 10,
      name: "TIT-TFT-BUS-S-3R",
      image: `${modelBasePath}/models/10.png`,
    },

  ];
  return (
    <div className={styles.container}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Titan;
