import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";
import ClickSlider from "./ClickSlider";

function ClickSeries() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const { t } = useTranslation();
  const imageData = [
    {
      img: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: ``,
      top: isSmallScreen ? "80%" : "50%",
      left: isSmallScreen ? "50%" : "20%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "up",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },

    {
      img: "02.jpg",
      title: "",
      subtitle: t(`hotelClick.subtitle1`),
      top: isSmallScreen ? "80%" : "45%",
      left: isSmallScreen ? "50%" : "20%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "up",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },

    {
      img: isSmallScreen ? "03.jpg" : "03.jpg",
      title: "",
      subtitle: t(`hotelClick.subtitle2`),
      top: isSmallScreen ? "23%" : "22%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "up",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },

    {
      img: "04.jpg",
      title: "",
      subtitle: t(`hotelClick.subtitle3`),
      top: isSmallScreen ? "23%" : "50%",
      left: isSmallScreen ? "50%" : "80%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "up",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },

    {
      img: isSmallScreen ? "05.png" : "05.jpg",
      title: "",
      subtitle: t(`hotelClick.subtitle4`),
      top: isSmallScreen ? "10%" : "65%",
      left: isSmallScreen ? "50%" : "82%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "up",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
  ];

  const basePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/hotel-interface-details/click-series`;
  const smallScreenPath = `${basePath}/smallScreens`;

  const modelsData = [
    {
      id: 1,
      name: "CLK-HDMI-A",
      image: `${basePath}/models/01.png`,
    },
    {
      id: 2,
      name: "CLK-HDMI-B",
      image: `${basePath}/models/02.png`,
    },
    {
      id: 3,
      name: "CLK-HTL-CRD-BLK",
      image: `${basePath}/models/03.png`,
    },
    {
      id: 4,
      name: "CLK-HTL-CRD-GOL",
      image: `${basePath}/models/04.png`,
    },
    {
      id: 5,
      name: "CLK-HTL-CRD-GRY",
      image: `${basePath}/models/05.png`,
    },
    {
      id: 6,
      name: "CLK-HTL-SRV-BLK",
      image: `${basePath}/models/06.png`,
    },
    {
      id: 7,
      name: "CLK-HTL-SRV-GOL",
      image: `${basePath}/models/07.png`,
    },
    {
      id: 8,
      name: "CLK-HTL-SRV-GRY",
      image: `${basePath}/models/08.png`,
    },
    {
      id: 9,
      name: "TIS-CLK-1B-1F-BLK",
      image: `${basePath}/models/09.png`,
    },
    {
      id: 10,
      name: "TIS-CLK-1B-1F-GOL",
      image: `${basePath}/models/10.png`,
    },
    {
      id: 11,
      name: "TIS-CLK-1B-1F-GRY",
      image: `${basePath}/models/11.png`,
    },
    {
      id: 12,
      name: "TIS-CLK-1B-2F-BLK",
      image: `${basePath}/models/12.png`,
    },
    {
      id: 13,
      name: "TIS-CLK-1B-2F-GOL",
      image: `${basePath}/models/13.png`,
    },
    {
      id: 14,
      name: "TIS-CLK-1B-2F-GRY",
      image: `${basePath}/models/14.png`,
    },
    {
      id: 15,
      name: "TIS-CLK-2B-1F-BLK",
      image: `${basePath}/models/15.png`,
    },
    {
      id: 16,
      name: "TIS-CLK-2B-1F-GOL",
      image: `${basePath}/models/16.png`,
    },
    {
      id: 17,
      name: "TIS-CLK-2B-1F-GRY",
      image: `${basePath}/models/17.png`,
    },
    {
      id: 18,
      name: "TIS-CLK-3B-1F-BLK",
      image: `${basePath}/models/18.png`,
    },
    {
      id: 19,
      name: "TIS-CLK-3B-1F-GOL",
      image: `${basePath}/models/19.png`,
    },
    {
      id: 20,
      name: "TIS-CLK-3B-1F-GRY",
      image: `${basePath}/models/20.png`,
    },
    {
      id: 21,
      name: "TIS-CLK-4B-1F-BLK",
      image: `${basePath}/models/21.png`,
    },
    {
      id: 22,
      name: "TIS-CLK-4B-1F-GOL",
      image: `${basePath}/models/22.png`,
    },
    {
      id: 23,
      name: "TIS-CLK-4B-1F-GRY",
      image: `${basePath}/models/23.png`,
    },
    {
      id: 24,
      name: "TIS-CLK-6B-1F-BLK",
      image: `${basePath}/models/24.png`,
    },
    {
      id: 25,
      name: "TIS-CLK-6B-1F-GOL",
      image: `${basePath}/models/25.png`,
    },
    {
      id: 26,
      name: "TIS-CLK-6B-1F-GRY",
      image: `${basePath}/models/26.png`,
    },
    {
      id: 27,
      name: "TIS-CLK-AC-BLK",
      image: `${basePath}/models/27.png`,
    },
    {
      id: 28,
      name: "TIS-CLK-AC-GOL",
      image: `${basePath}/models/28.png`,
    },
    {
      id: 29,
      name: "TIS-CLK-AC-GRY",
      image: `${basePath}/models/29.png`,
    },
  ];

  return (
    <div className={styles.container}>
      {imageData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100vw", display: "block" }}
        >
          {item.isVideo ? (
            <VideoAsGif
              className={styles.videoContainer}
              style={{ width: "100vw" }}
              videoSrc={`${basePath}/${item.img}`}
            />
          ) : (
            <img
              style={{
                width: "100vw",
                marginRight: isSmallScreen ? "0" : "0",
                paddingLeft: isSmallScreen ? "0" : "0",
              }}
              src={
                isSmallScreen
                  ? `${smallScreenPath}/${item.img}`
                  : `${basePath}/${item.img}`
              }
              alt=""
              onLoad={() => handleImageLoad(index)}
            />
          )}
          {imagesLoaded[index] && (
            <div
              style={{
                position: "absolute",
                top: item.top,
                left: item.left,
                transform: "translate(-50%, -50%)",
                textAlign: item.textAlign,
                width: item.width,
              }}
            >
              <AnimatedDetailsText
                text={{
                  title: item.title,
                  subtitle: item.subtitle,
                }}
                color={item.color || "#000"}
                textAlign={item.textAlign}
                margin="20px 0"
                padding="10px"
                direction={item.direction}
                fontSize={item.fontSize || "20px"}
                zoom={item.zoom}
              />
            </div>
          )}
          {/* عرض مكون ModelSlider بعد الصورة الأولى فقط */}
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} customPadding="0 0 55px" />
              </div>
              <ClickSlider />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ClickSeries;

const VideoAsGif = ({ videoSrc, className }) => {
  return (
    <div className={styles.videoContainer}>
      <video
        className={styles.fullscreenVideo}
        autoPlay
        muted
        loop
        playsInline // مهم لأجهزة iPhone
        disablePictureInPicture // منع خاصية "صورة داخل صورة"
        controls={false} // إخفاء أزرار التحكم
        style={{ display: "block", width: "100%" }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
