import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
import ModelSlider from "../../../ModelSlider";

function MotionsSensors() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // const [imagesLoaded, setImagesLoaded] = useState({});
  const { i18n } = useTranslation(); // إضافة i18n للحصول على اللغة الحالية

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleImageLoad = (index) => {
  //   setImagesLoaded((prevState) => ({
  //     ...prevState,
  //     [index]: true,
  //   }));
  // };

  // const basePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/sensors-details/motion-sensor`;
  // const smallScreenPath = `${basePath}/smallScreens`;

  const { t } = useTranslation();

  const imageBasePath = isSmallScreen
  ? `${process.env.PUBLIC_URL}/images/01-tis-bus/sensors-details/motion-sensor/smallScreens`
  : `${process.env.PUBLIC_URL}/images/01-tis-bus/sensors-details/motion-sensor`

const modelBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/sensors-details/motion-sensor`

const videoBasePath = `${process.env.PUBLIC_URL}/images/01-tis-bus/sensors-details/motion-sensor`; // Assuming video path

  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: "",
      subtitle: t(`sensorMotion.subtitle1`),
      top: isSmallScreen ? "15%" : "15%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "60%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "02.mp4",
      title: "",
      subtitle: t(`sensorMotion.subtitle2`),
      top: isSmallScreen ? "83%" : "57%",
      left: isSmallScreen ? "60%" : "27%",
      textAlign: isSmallScreen ? "left" : "left",
      width: isSmallScreen ? "60%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "03.mp4",
      title: "",
      subtitle: t(`sensorMotion.subtitle3`),
      top: isSmallScreen ? "38%" : "40%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      type: "image",
      src: isSmallScreen ? "04.jpg" : "04.jpg",
      title: "",
      subtitle: t(`sensorMotion.subtitle4`),
      top: isSmallScreen ? "80%" : "45%",
      left: isSmallScreen ? "58%" : "28%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "75%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "05.jpg" : "05.jpg",
      title: "",
      subtitle: t(`sensorMotion.subtitle5`),
      top: isSmallScreen ? "50%" : "50%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      type: "video",
      src: "06.mp4",
      title: "",
      subtitle: t(`sensorMotion.subtitle6`),
      top: isSmallScreen ? "45%" : "50%",
      left: isSmallScreen ? "25%" : "21%",
      textAlign: isSmallScreen ? "left" : "left",
      width: isSmallScreen ? "40%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "07.mp4",
      title: "",
      subtitle: t(`sensorMotion.subtitle7`),
      top: isSmallScreen ? "40%" : "70%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "26%",
      direction: isSmallScreen ? "up" : "down",
      zoom: false,
      color: isSmallScreen ? "#fff" : "#fff",
    },

  ];



  const modelsData = [
    {
      id: 1,
      name: "MS-PIR-CM",
      image: `${modelBasePath}/models/01.png`,
    },
    {
      id: 2,
      name: "MS-PIR-IC",
      image: `${modelBasePath}/models/02.png`,
    },
  ];
  const textDirection = i18n.language === "ar" ? "rtl" : "ltr"; // تعيين اتجاه الكتابة بناءً على اللغة
  return (
    <div className={styles.container} style={{ direction: textDirection }}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
export default MotionsSensors;


