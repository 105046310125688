import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import YouTube from "react-youtube";
import { motion } from "framer-motion";
import styles from "./ProjectsDetailsV.module.css";
import { useTranslation } from "react-i18next";
import Spinner from "../../../00-Main/spinner/Spinner";

const ProjectThree = () => {
  const { t } = useTranslation();

  const shorts = useMemo(
    () => [
      { id: 1, title: t('videos.1'), videoId: "IVsNINKljRc" },
      { id: 2, title: t('videos.2'), videoId: "2G6Lsw3oQD0" },
      { id: 3, title: t('videos.3'), videoId: "FzKpMA8DJP0" },
    ],
    [t]
  );

  const [loading, setLoading] = useState(false);
  const playerRefs = useRef([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleResizeB = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener("resize", handleResizeB);
    return () => window.removeEventListener("resize", handleResizeB);
  }, []);

  const getPlayerOptions = useCallback(
    () => ({
      width: "280",
      height: "500",
      playerVars: {
        autoplay: isSmallScreen ? 1 : 0,
        mute: isSmallScreen ? 1 : 0,
        controls: isSmallScreen ? 0 : 1,
        rel: 0,
        playsinline: 1, // يضمن التشغيل داخل الصفحة على iOS
        modestbranding: 1, // إخفاء شعار يوتيوب
        showinfo: 0, // إخفاء معلومات الفيديو
      },
    }),
    []
  );

  const handlePlay = useCallback(
    (index) => {
      playerRefs.current.forEach((player, i) => {
        if (player && i !== index && typeof player.pauseVideo === "function") {
          player.pauseVideo();
        }
      });

      const currentVideo = playerRefs.current[index];
      if (currentVideo && typeof currentVideo.playVideo === "function") {
        currentVideo.playVideo();
      }
    },
    [shorts]
  );

  useEffect(() => {
    if (window.innerWidth > 768) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.dataset.index);
          if (entry.isIntersecting) {
            handlePlay(index);
          } else {
            playerRefs.current[index]?.pauseVideo();
          }
        });
      },
      { threshold: 0.8 }
    );

    const elements = document.querySelectorAll(`.${styles.videoWrapper}`);
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [handlePlay]);

  return (
    <div className={styles.container}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <motion.h1
            className={styles.title}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {t("Project_details")}
          </motion.h1>

          <motion.p
            className={styles.description}
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 60 }}
          >
            {t("Project_description")}
          </motion.p>
          <div className={styles.videoGrid}>
            {shorts.map((short, index) => (
              <motion.div
                className={styles.videoCard}
                key={short.id}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5, delay: short.id * 0.2 }}
              >
                <h2>{short.title}</h2>
                <div className={styles.videoWrapper} data-index={index}>
                  <YouTube
                    videoId={short.videoId}
                    opts={getPlayerOptions()}
                    onReady={(event) => {
                      playerRefs.current[index] = event.target;
                      event.target.mute(); // ضمان كتم الصوت عند البدء
                      if (isSmallScreen) {
                        event.target.playVideo();
                      }
                    }}
                    onPlay={() => handlePlay(index)}
                    className={styles.video}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectThree;
