import React, { useEffect, useState } from "react";
import styles from './ScrollToTop.module.css';
import { FaArrowUp } from 'react-icons/fa'; // استيراد أيقونة من React Icons

const ScrollToTop = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    // window.addEventListener("scroll", handleScroll);
    window.addEventListener('scroll', handleScroll, { passive: true });


    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`${styles.toTop} ${showArrow ? styles.show : ''}`}
      onClick={scrollToTop}
    >
      <FaArrowUp className={styles.arrowIcon} />
    </div>
  );
};

export default ScrollToTop;
