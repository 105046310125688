import { useEffect, useState } from "react";
import styles from "../../../ProductsDetails.module.css";
import { useTranslation } from "react-i18next";
import AnimatedDetailsText from "../../../AnimatedDetailsText";
// import ModelSlider from "../../../ModelSlider";

function RgbwDriver() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // const [imagesLoaded, setImagesLoaded] = useState({});
  const { i18n } = useTranslation(); // إضافة i18n للحصول على 

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // const handleImageLoad = (index) => {
  //   setImagesLoaded((prevState) => ({
  //     ...prevState,
  //     [index]: true,
  //   }));
  // };
  const { t } = useTranslation();

  // const basePath = `${process.env.PUBLIC_URL}/images/03-tis-bee/controller/details/rgbw-driver`;
  // const smallScreenPath = `${basePath}/smallScreens`;


  const imageBasePath = isSmallScreen
  ? `${process.env.PUBLIC_URL}/images/03-tis-bee/controller/details/rgbw-driver/smallScreens`
  : `${process.env.PUBLIC_URL}/images/03-tis-bee/controller/details/rgbw-driver`

//const modelBasePath = `${process.env.PUBLIC_URL}/images/03-tis-bee/controller/details/rgbw-driver`

const videoBasePath = `${process.env.PUBLIC_URL}/images/03-tis-bee/controller/details/rgbw-driver`; // Assuming video path


  const mediaData = [
    {
      type: "image",
      src: isSmallScreen ? "01.jpg" : "01.jpg",
      title: t(`beeRgbwDriver.title1`),
      subtitle: t(`beeRgbwDriver.subtitle1`),

      top: isSmallScreen ? "85%" : "25%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "45%",
      direction: isSmallScreen ? "up" : "down",
      zoom: true,
      color: isSmallScreen ? "#fff" : "#fff",
    },
    {
      type: "video",
      src: "02.mp4",
      title: "",
      subtitle: t(`beeRgbwDriver.subtitle2`),
      top: isSmallScreen ? "50%" : "40%",
      left: isSmallScreen ? "20%" : "20%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "40%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "03.jpg" : "03.jpg",
      title: "",
      subtitle: t(`beeRgbwDriver.subtitle3`),
      top: isSmallScreen ? "53%" : "57%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "40%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "04.jpg" : "04.jpg",
      title: "",
      subtitle: t(`beeRgbwDriver.subtitle4`),
      top: isSmallScreen ? "20%" : "50%",
      left: isSmallScreen ? "50%" : "70%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "65%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "05.jpg" : "05.jpg",
      title: "",
      subtitle: t(`beeRgbwDriver.subtitle5`),
      
      top: isSmallScreen ? "70%" : "50%",
      left: isSmallScreen ? "50%" : "25%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "60%" : "20%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "video",
      src: "06.mp4",
      title: t(`beeRgbwDriver.title6`),
      subtitle: t(`beeRgbwDriver.subtitle6`),

      top: isSmallScreen ? "20%" : "20%",
      left: isSmallScreen ? "50%" : "50%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "07.jpg" : "07.jpg",
      title: t(`beeRgbwDriver.title7`),
      subtitle: t(`beeRgbwDriver.subtitle7`),

      top: isSmallScreen ? "70%" : "45%",
      left: isSmallScreen ? "50%" : "27%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "70%" : "30%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "08.jpg" : "08.jpg",
      title: t(`beeRgbwDriver.title8`),
      subtitle: t(`beeRgbwDriver.subtitle8`),

      top: isSmallScreen ? "15%" : "40%",
      left: isSmallScreen ? "50%" : "75%",
      textAlign: isSmallScreen ? "center" : "center",
      width: isSmallScreen ? "50%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#000" : "#000",
    },
    {
      type: "image",
      src: isSmallScreen ? "09.jpg" : "09.jpg",
      title: t(`beeRgbwDriver.subtitle9`),
      subtitle: t(`beeRgbwDriver.subtitle9`),

      top: isSmallScreen ? "82%" : "45%",
      left: isSmallScreen ? "50%" : "22%",
      textAlign: isSmallScreen ? "center" : "left",
      width: isSmallScreen ? "70%" : "25%",
      direction: isSmallScreen ? "up" : "down",
      //zoom: true,
      color: isSmallScreen ? "#fff" : "#fff",
    },
  ];



  // const modelsData = [
  //   {
  //     id: 1,
  //     name: "TIS-IP-INT-OUT-DB10",
  //     image: `${basePath}/models/01.png`,
  //   },
  // ];

  const textDirection = i18n.language === "ar" ? "rtl" : "ltr"; // تعيين اتجاه الكتابة بناءً على اللغة
  return (
    <div className={styles.container} style={{ direction: textDirection }}>
      {mediaData.map((item, index) => (
        <div
          key={index}
          className={styles.imgContainer}
          style={{ position: "relative", width: "100%", display: "block" }}
        >
          {item.type === "image" ? (
            <img
              style={{ width: "100%" }}
              src={`${imageBasePath}/${item.src}`}
              alt=""
            />
          ) : (
            <div className={styles.videoContainer}>
              <video
                className={styles.fullscreenVideo}
                src={`${videoBasePath}/${item.src}`}
                autoPlay
                muted
                loop
                playsInline // مهم لأجهزة iPhone
                disablePictureInPicture // منع خاصية "صورة داخل صورة"
                controls={false} // إخفاء أزرار التحكم
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              textAlign: item.textAlign,
              width: item.width,
              color: item.color || "#000", // استخدام لون النص المحدد
            }}
          >
            <AnimatedDetailsText
              text={{
                title: item.title,
                subtitle: item.subtitle,
              }}
              textAlign={item.textAlign}
              margin="20px 0"
              padding="10px"
              direction={item.direction}
              zoom={item.zoom}
              fontSize="20px"
              color={item.color} // تمرير اللون إلى `AnimatedDetailsText`
            />
          </div>
          {/* {index === 0 && (
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                Product Models
              </h1>
              <div>
                <ModelSlider models={modelsData} />
              </div>
            </div>
          )} */}
        </div>
      ))}
    </div>
  );
}

export default RgbwDriver;


