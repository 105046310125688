// import React, { useState } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './AnimatedDetailsText.module.css'; // ملف الـ CSS


const AnimatedDetailsText = ({ 
  text, 
  color = "var(--text-p-color)", 
  textAlign = "center", 
  margin = "0", 
  padding = "0", 
  direction = "up",  // الاتجاه الافتراضي هو الأعلى
  zoom = false,
  backgroundColor = "transparent",
  borderRadius = "10px",
  zIndex ="6666666666666"
  //fontSize = "18px"// الافتراضي هو عدم التكبير
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,  
    threshold: 0.1,    
  });

  // اختر الحركة بناءً على اتجاه `direction`
  const getAnimationClass = () => {
    let animationClass;
    switch (direction) {
      case 'up':
        animationClass = styles.fadeInUp;
        break;
      case 'down':
        animationClass = styles.fadeInDown;
        break;
      case 'left':
        animationClass = styles.fadeInLeft;
        break;
      case 'right':
        animationClass = styles.fadeInRight;
        break;
      default:
        animationClass = styles.fadeInUp; // الافتراضي
    }

    // إضافة تأثير التكبير إذا تم تحديده
    if (zoom) {
      animationClass += ` ${styles.zoomIn}`;
    }

    return animationClass;
  };

  return (
    <p
      ref={ref}
      className={`${styles.text} ${inView ? getAnimationClass() : ''}`}
      style={{
        color,
        textAlign,
        margin,
        padding,
        //fontSize
        backgroundColor,
        borderRadius,
        zIndex,
        
      }}
    >
      <strong>{text.title}</strong>
      <br />
      {text.subtitle}
    </p>
  );
};

export default AnimatedDetailsText;
