// import React, { useState } from "react";
// import styles from "./ImageSwitcher.module.css";

// const ImageSwitcher = ({ images }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   return (
//     <div className={styles.imageSwitcher}>
//       <button className={styles.arrow} onClick={handlePrev}>
//         &#8592;
//       </button>
//       <img
//         src={`${process.env.PUBLIC_URL}${images[currentIndex]}`}
//         alt={`image-${currentIndex + 1}`}
//         className={styles.image}
//       />
//       <button className={styles.arrow} onClick={handleNext}>
//         &#8594;
//       </button>
//     </div>
//   );
// };

// export default ImageSwitcher;


import React, { useState } from "react";
import styles from "./ImageSwitcher.module.css";

const ImageSwitcher = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={styles.imageSwitcher}>
      <img
        src={`${process.env.PUBLIC_URL}${images[currentIndex]}`}
        alt={`image-${currentIndex + 1}`}
        className={styles.image}
        onClick={handleImageClick} // التغيير عند الضغط على الصورة
      />
    </div>
  );
};

export default ImageSwitcher;
