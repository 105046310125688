import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styles from "../hik.module.css";

const PoeVilla = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      className={styles.productDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className={styles.productImage}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/intercom/details/PoeVilla/01.jpg`}
          alt={t("poeVilla.alt")}
        />
      </motion.div>
      <motion.div
        className={styles.productInfo}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className={styles.productTitle}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          {t("poeVilla.title")}
        </motion.h1>
        <motion.ul className={styles.productFeatures}>
          {[
            t("poeVilla.features.camera"),
            t("poeVilla.features.callButton"),
            t("poeVilla.features.cardReader"),
            t("poeVilla.features.poePower"),
            t("poeVilla.features.ipProtection"),
            t("poeVilla.features.hikConnect"),
          ].map((feature, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 + index * 0.2, duration: 0.6 }}
            >
              {feature}
            </motion.li>
          ))}
          <motion.li
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.8, duration: 0.6 }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              {[...Array(5)].map((_, i) => (
                <img
                  key={i}
                  style={{ width: "40px" }}
                  src={`${process.env.PUBLIC_URL}/images/05-hikvision/cam/intercom/details/PoeVilla/icons/0${i + 1}.png`}
                  alt=""
                />
              ))}
            </div>
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default PoeVilla;
